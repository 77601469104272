import React from "react";

import img_1 from "../resources/images/landing-page/section6/section6_1.png";
import img_2 from "../resources/images/landing-page/section6/section6_2.png";
import img_3 from "../resources/images/landing-page/section6/section6_3.png";

import { Box, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  borderRadius: 20,
  padding: 14,
  marginLeft: 10,
  marginRight: 10,
  width: "18rem",
}));

const Section6 = (props) => {
  const nowMediaQuery = props.nowMediaQuery;

  return (
    <section className="section contents background-light-gray">
      <Stack
        direction={nowMediaQuery === "pc" ? "row" : "column"}
        container
        className=" center_align"
      >
        <Grid item md={5} sm={12} xs={12} p={2} m={1}>
          <Typography variant="h4" className="bold blue" mb={4}>
            지자체 공식 서비스
          </Typography>
          <Box display={"flex"}>
            <Item elevation={0}>
              <img className="section6-img" src={img_1} alt="shield img" />
              <Typography variant="h6">
                ICT 규제샌드박스
                <br />
                시범사업 수행중(화성, 창원)
              </Typography>
            </Item>
            <Item elevation={0}>
              <img className="section6-img" src={img_2} alt="shield img" />
              <Typography variant="h6">
                지자체 전용
                <br />
                서비스 개발(창원퍼피)
              </Typography>
            </Item>
          </Box>
        </Grid>

        <Divider orientation="vertical" flexItem />

        <Grid item md={5} sm={12} xs={12} p={2} m={1}>
          <Typography variant="h4" className="bold blue" mb={4}>
            기술력을 인정받은 서비스
          </Typography>
          <Box display={"flex"}>
            <Item elevation={0}>
              <img className="section6-img" src={img_3} alt="shield img" />
              <Typography variant="h6">
                <strong>대통령 표창</strong> <br />
                농림축산식품 과학기술대상
              </Typography>
            </Item>
            <Item elevation={0}>
              <img className="section6-img" src={img_3} alt="shield img" />
              <Typography variant="h6">
                <strong>과기부 장관 표창</strong> <br />
                2023 디지털 이노베이션
              </Typography>
            </Item>
          </Box>
        </Grid>
      </Stack>

      <Link to="/tech-story">
        <button className="blue-button font-default">더보기</button>
      </Link>
    </section>
  );
};

export default Section6;
