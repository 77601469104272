import React from "react";
import useScroll from "../hooks/useScroll";
import { ANDROID_INSTALL, IOS_INSTALL } from "../words";

const CTABtn = () => {
  const { y } = useScroll();
  let downloadLink;

  const varUA = navigator.userAgent.toLowerCase();
  if (varUA.indexOf("android") > -1) {
    //안드로이드
    downloadLink = ANDROID_INSTALL;
  } else if (
    varUA.indexOf("iphone") > -1 ||
    varUA.indexOf("ipad") > -1 ||
    varUA.indexOf("ipod") > -1
  ) {
    //IOS
    downloadLink = IOS_INSTALL;
  } else {
    downloadLink = ANDROID_INSTALL;
  }

  return (
    <a href={downloadLink}>
      <div className={y > 0 ? "cta-button floating-button" : "cta-button"}>
        울 강아지 등록하기
      </div>
    </a>
  );
};

export default CTABtn;
