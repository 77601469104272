import React from "react";
import Section4Slider from "../components/Section4Slider";

const Section4 = (props) => {
  const nowMediaQuery = props.nowMediaQuery;

  return (
    <section className="section">
      <div className="contents process center_align">
        <div className="carousel_div">
          <Section4Slider nowMediaQuery={nowMediaQuery} slidesToShow={1} />
        </div>
      </div>
    </section>
  );
};

export default Section4;
