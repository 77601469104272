import React from "react";

import img_pc from "../resources/images/landing-page/section3/section3_pc.png";
import img_mobile from "../resources/images/landing-page/section3/section3_mobile.png";
import { Typography } from "@mui/material";

const Section3 = (props) => {
  const nowMediaQuery = props.nowMediaQuery;

  return (
    <section className="section background-light-blue">
      <div className="contents center_align">
        <Typography variant="h3" className="bold">
          <strong className="blue">동물등록</strong>과{" "}
          <strong className="blue">찾는 방법</strong>을
          <br />
          간단하게 알려드릴게요.
        </Typography>
        <Typography variant="h5" my={5}>
          애니퍼피는 <span className="blue">독보적인 AI 기술</span>을 활용하여
          동물을 등록하고
          <br />
          잃어버렸을 시, 비문 촬영으로{" "}
          <span className="blue">반려견의 정보를 확인</span>할 수 있어요.
        </Typography>

        <div className="relative">
          {nowMediaQuery === "mobile" && (
            <img src={img_mobile} className="img_process" alt="" />
          )}
          {nowMediaQuery === "tablet" && (
            <img src={img_pc} className="img_process" alt="" />
          )}
          {nowMediaQuery === "pc" && (
            <img src={img_pc} className="img_process" alt="" />
          )}
        </div>
      </div>
    </section>
  );
};

export default Section3;
