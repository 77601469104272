import React from "react";
import image404 from "../resources/images/404.png";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

const NotFound = (props) => {
  return (
    <div className={(props.nowMediaQuery, "FAQ")}>
      <section className="not_found contents_section">
        <img src={image404} alt="" />
        <Typography
          variant="h3"
          className="slogan"
          color="black"
          textAlign="center"
        >
          죄송합니다. 해당 페이지를 찾을 수 없습니다.
        </Typography>

        <Typography
          variant="h5"
          textAlign="center"
          sx={{ mt: 2 }}
          color="black"
        >
          존재하지 않는 주소를 입력하셨거나,
          <br />
          요청하신 페이지의 주소가 변경/삭제되어 찾을 수 없습니다.
        </Typography>
        <Link
          to="/"
          rel="nosublink"
          onClick={window.history.replaceState(null, null, " ", "/")}
        >
          <button className="btn404">
            <Typography variant="h5" textAlign="center">
              메인으로 이동{" "}
            </Typography>
          </button>
        </Link>
      </section>
    </div>
  );
};

export default NotFound;
