import React from "react";
import ReactDOM from "react-dom/client";
import ScrollToTop from "./components/ScrollToTop";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "../src/resources/styles/base.css";
import "../src/resources/styles/common.scss";
import "../src/resources/styles/faq.scss";
import "../src/resources/styles/subPage.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "swiper/css/bundle";

import ReactPixel from "react-facebook-pixel";

ReactPixel.init("615525960128396");
ReactPixel.pageView(); // For tracking page view

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
