import React from "react";
import img_nose from "../resources/images/landing-page/section2/section2_1.png";
import img_nose_line from "../resources/images/landing-page/section2/section2_2.png";
import { Grid, Typography } from "@mui/material";

const Section2 = (props) => {
  return (
    <section className="section importance_section">
      <div className="contents center_align">
        <Typography variant="h3" className="bold">
          비문이 왜 중요할까요?
        </Typography>
        <Typography variant="h5" mt={3}>
          강아지 코를 유심히 보면 주름이 있는 것을 알 수 있어요. <br />이
          자글자글한 주름을 바로{" "}
          <span className="blue">
            비문(<strong className="hanja">鼻紋</strong>)
          </span>
          이라고 해요.
        </Typography>
        <Typography variant="h5" my={4}>
          비문은 사람의 지문처럼{" "}
          <span className="blue">나이가 들어도 변하지 않는 생체정보</span>
          로,
          <br />
          동물을 등록하고 누구인지 확인할 때 사용이 가능해요.
        </Typography>

        <div className="relative">
          <div className="center_align div_nose">
            <img className="nose" src={img_nose} alt="" />
            <img className="nose_line" src={img_nose_line} alt="" />
          </div>
        </div>

        <Grid
          container
          mt={3}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ width: "90%" }}
        >
          <Grid item className="paper" md={3} sm={12} xs={12} p={2} m={1}>
            <Typography variant="h5">
              동물병원 방문 없이, <br />
              집에서 내가 <span className="blue">직접 등록 가능</span>해요
            </Typography>
          </Grid>
          <Grid item className="paper" md={3} sm={12} xs={12} p={2} m={1}>
            <Typography variant="h5">
              <span className="blue">통증, 부작용 걱정없이</span>
              <br />코 사진 촬영만으로 등록 가능해요
            </Typography>
          </Grid>
          <Grid item className="paper" md={3} sm={12} xs={12} p={2} m={1}>
            <Typography variant="h5">
              <span className="blue">코 사진 촬영만으로</span>
              <br /> 이 강아지가 누구인지 알 수 있어요
            </Typography>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default Section2;
