import React from "react";

export default function TermsGhana() {
  return (
    <>
      <div className="terms">
        <div>
          <h1>iSciLab Service (AniPuppy) Terms of Use</h1>

          <h2>Chapter 1 General Provisions</h2>

          <h3>Article 1 [Purpose]</h3>
          <p>
            These terms and conditions set forth the rights, obligations,
            responsibilities, and other necessary matters between iSciLab Co.,
            Ltd. (the "Company") and the members regarding the use of the
            Company’s related services. These terms and conditions shall also
            apply to electronic commerce using PC communication, smartphone
            applications (Android, iPhone, etc.), and other means, as long as
            they do not conflict with the nature of such transactions.
          </p>

          <h3>Article 2 [Definitions]</h3>
          <p>
            The definitions of the terms used in these terms and conditions are
            as follows:
          </p>
          <ol>
            <li>
              <strong>"Service"</strong> refers to all services provided by the
              "Company," regardless of the type of wired or wireless terminal
              device accessible.
            </li>
            <li>
              <strong>"User"</strong> refers to members and non-members who
              access the Company’s services and use the "contents" and related
              services provided by the Company under these terms and conditions.
            </li>
            <li>
              <strong>"Member"</strong> refers to a user who has signed a usage
              contract with iSciLab and has been given a user ID (ID) to
              continuously receive information from iSciLab and use its
              services.
            </li>
            <li>
              <strong>"ID"</strong> refers to a combination of characters or
              numbers designated by the "Member" and approved by iSciLab for the
              identification and use of services by the "Member."
            </li>
            <li>
              <strong>"Password"</strong> refers to a combination of characters
              or numbers set by the "Member" to verify that they are the
              rightful holder of the assigned "ID" and to ensure
              confidentiality.
            </li>
            <li>
              <strong>"Withdrawal"</strong> refers to the termination of the
              usage contract after the service is opened by either the company
              or the member.
            </li>
            <li>
              <strong>"Content"</strong> refers to materials or information
              expressed in symbols, characters, sound, voice, images, or videos
              created or processed in an electronic form that enhances the
              utility of information stored and used, including text, photos,
              videos, music, various files, and links operated by the Company or
              members.
            </li>
          </ol>
          <p>
            These terms and conditions shall comply with relevant laws and
            regulations.
          </p>

          <h3>Article 3 [Rules Outside the Terms]</h3>
          <p>
            The operator may provide separate operating policies if necessary,
            and if these terms and policies overlap, the operating policy takes
            precedence.
          </p>

          <h3>
            Article 4 [Specification, Effectiveness, and Changes to the Terms]
          </h3>
          <ol>
            <li>
              These terms and conditions take effect when posted online and
              publicly announced to users regarding the services provided by the
              Company (hereinafter referred to as "Services").
            </li>
            <li>
              The Company may revise these terms and conditions within the
              limits of not violating relevant laws such as the "Consumer
              Protection Act in Electronic Commerce," "Act on the Regulation of
              Terms and Conditions," "Basic Act on Electronic Documents and
              Electronic Transactions," "Electronic Financial Transactions Act,"
              "Electronic Signature Act," "Act on Promotion of Information and
              Communications Network Utilization and Information Protection,"
              "Basic Consumer Act," and others.
            </li>
            <li>
              If there is an important revision to the terms and conditions
              affecting the rights or obligations of users, the Company will
              notify users in advance. However, in cases of unfavorable changes,
              the Company will explicitly notify users via electronic means such
              as email, electronic messages, or consent windows during login.
            </li>
            <li>
              If the Company provides notice of the revised terms and informs
              members that failure to express dissent within seven days will be
              deemed consent, members will be considered to have agreed to the
              revised terms unless they explicitly express dissent within the
              period.
            </li>
            <li>
              Agreeing to these terms means agreeing to check for updates to the
              terms regularly. The Company will not be held responsible for any
              damages incurred due to members not being aware of the changes.
            </li>
            <li>
              If a member disagrees with the revised terms, the Company may
              request the withdrawal (termination) of that member.
            </li>
          </ol>

          <h3>Article 5 [Establishment of the Membership Agreement]</h3>
          <p>
            If the applicant reads these terms and clicks the "Agree" button or
            checks "Confirm," it is considered that they have agreed to these
            terms. iSciLab may request identity verification through a
            professional institution for real-name and personal identification
            purposes. The applicant must provide required personal information
            (name, address, ID, password, phone number, email address) upon the
            Company’s request. Depending on the service the applicant intends to
            use, the applicant must provide required pet information (photos,
            breed, age, gender, coat color, neuter status, etc.). iSciLab
            generally accepts the applicant’s service request. However, the
            Company may reject or terminate the member contract later under the
            following circumstances:
          </p>
          <ol>
            <li>
              The applicant is not using their real name or is using another
              person’s name.
            </li>
            <li>
              The applicant had previously lost membership status under these
              terms, except when the Company approves their re-registration.
            </li>
            <li>
              The applicant provided false information or failed to provide
              required information.
            </li>
            <li>The applicant uses the service for fraudulent purposes.</li>
            <li>
              The applicant applies for the service in violation of relevant
              laws or with the intent to disrupt public order or good morals.
            </li>
            <li>
              The application is rejected for reasons attributable to the user
              or violates any other applicable provisions.
            </li>
            <li>
              The Company may postpone approval if there is no available service
              infrastructure or technical or business issues.
            </li>
            <li>
              If the Company does not accept or postpones the application under
              Clause 5 or Clause 6, the Company shall notify the applicant in
              principle.
            </li>
          </ol>
          <p>
            The membership agreement is established when the applicant clicks
            the "Sign Up" or "Confirm" button after filling out the required
            information, including the member ID.
          </p>

          <h3>Article 6 [Changes to Member Information]</h3>
          <ol>
            <li>
              "Members" may view and modify their personal information at any
              time via the personal information management screen.
            </li>
            <li>
              "Members" must update their information online or notify iSciLab
              of any changes via email or other methods.
            </li>
            <li>
              iSciLab shall not be responsible for any disadvantages resulting
              from a failure to notify the Company of changes in accordance with
              Clause 2.
            </li>
          </ol>

          <h3>Article 7 [Use of Service]</h3>
          <p>
            The Company begins providing services immediately upon approval of
            the member’s application. However, if there are business or
            technical issues, the Company will notify members and post an
            announcement on the service. Service is available 24/7, year-round
            unless there are system maintenance, expansion, or replacement
            issues for which the Company will provide prior notice. However, in
            the event of emergencies such as urgent system repairs, power
            outages, or service facility failures, the Company may temporarily
            suspend services without notice. The Company may set specific
            service hours and will notify members in advance. However, prior
            notice may not be possible for service interruptions beyond the
            Company’s control. The Company offers a mobile app version of the
            service for iPhones, Android OS devices, etc. Members can download
            and install the app to access the services. Features and
            configurations may vary depending on the mobile device and carrier.
          </p>

          <h3>Article 8 [Service Content]</h3>
          <p>
            The "Company" utilizes the information input/registered by the
            "Member" regarding themselves and their pets. The "Member’s"
            information and pet information are provided directly by the
            customer on the relevant smartphone. The "Company" may share the pet
            information directly inputted by the "Member" with "Pet Service
            Providers." Specific services offered by iSciLab include the
            following:
          </p>
          <ul>
            <li>iSciLab smartphone application</li>
            <li>
              Nose print recognition service: A service that registers and
              verifies the nose print of a pet registered by the member.
            </li>
            <li>
              Content service: Access to various content, such as news and
              pet-related knowledge provided by iSciLab or pet-related
              companies, is available for free.
            </li>
            <li>
              Stray animal search and adoption information service: A service
              that provides access to the list of stray and lost animals offered
              by the Animal Protection System API and enables inquiries about
              adoption.
            </li>
            <li>Pet community service</li>
            <li>
              Pet store information service: This service provides information
              about nearby pet-related services using the location information
              provided by members.
            </li>
          </ul>

          <h3>Article 9 [Protection and Management of Personal Information]</h3>
          <p>
            The "Company" endeavors to protect the personal information of
            "Members" as stipulated by relevant laws. The relevant laws and the
            Company’s privacy policy govern the protection and use of personal
            information. The Company’s privacy policy does not apply to linked
            sites other than the Company’s official site. However, the Company
            may share some personal information for business purposes as
            necessary with partner companies (Pet Service Providers) to improve
            service convenience.
          </p>

          <h3>Article 10 [Service Interruption]</h3>
          <p>
            The Company may temporarily suspend service provision due to system
            maintenance, expansion, replacement, or technical and business
            reasons. For planned work, the Company shall announce service
            suspension details in advance. However, in emergencies, the Company
            may suspend services without prior notice. In such cases, the
            Company will notify members as soon as possible.
          </p>

          <h3>Article 11 [Company's Obligations]</h3>
          <p>
            The Company endeavors to provide continuous and stable services as
            stipulated in these terms. However, the Company is not responsible
            for service disruptions caused by natural disasters, emergencies, or
            maintenance. The Company shall not use the personal information of
            "Members" for any purpose other than to provide the service without
            the user's consent, except where necessary for relevant legal
            obligations.
          </p>

          <h3>Article 12 [Member's Obligations]</h3>
          <p>
            Members must not engage in the following activities while using the
            services provided by the Company:
          </p>
          <ol>
            <li>
              Providing false information when applying for membership or
              changes
            </li>
            <li>
              Infringing on the intellectual property rights of the Company or
              third parties
            </li>
            <li>Spreading false information</li>
            <li>
              Engaging in behavior that harms the service system, such as
              hacking
            </li>
            <li>
              Sending or posting illegal information (e.g., virus distribution,
              spam, etc.)
            </li>
            <li>Engaging in acts that harm public order and morals</li>
          </ol>

          <h3>Article 13 [Termination and Restrictions on Use]</h3>
          <p>
            If a member intends to terminate the service, they must apply for
            termination through the relevant service screen. The Company will
            process the request immediately according to relevant laws. However,
            termination may not be possible for ongoing transactions or legal
            obligations. If the Company discovers that a member has violated
            these terms or other relevant laws, the Company may restrict or
            terminate the member’s use of services after notifying them in
            advance.
          </p>
        </div>
      </div>
    </>
  );
}
