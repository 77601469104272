import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";

import img_1 from "../resources/images/landing-page/section4/section4_1.png";
import img_2 from "../resources/images/landing-page/section4/section4_2.png";
import img_3 from "../resources/images/landing-page/section4/section4_3.png";
import img_4 from "../resources/images/landing-page/section4/section4_4.png";
import img_5 from "../resources/images/landing-page/section4/section4_5.png";
import prev_btn from "../resources/images/landing-page/section4/prev-btn.png";
import next_btn from "../resources/images/landing-page/section4/next-btn.png";

import { Box, Typography } from "@mui/material";

const Section4Slider = (props) => {
  const slidesToShow = props.slidesToShow;

  const [currentStep, setCurrentTab] = useState("1");

  let sliderRef = useRef(null);
  // console.log(sliderRef);

  useEffect(() => {
    // console.log(sliderRef);
    // console.log(currentStep);
  }, [sliderRef, currentStep]);

  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  let settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    swipeToSlide: false,
    afterChange: (index) => setCurrentTab((index + 1).toString()),
  };

  return (
    <div>
      <Slider
        className="carousel"
        {...settings}
        ref={(slider) => {
          sliderRef = slider;
        }}
      >
        <Box
          className="carousel-item"
          sx={{ display: "flex", direction: "column", alignItems: "center" }}
        >
          <Typography variant="h5" my={3}>
            <strong className="blue">비문등록</strong>
          </Typography>
          <Typography variant="h3" className="bold">
            앙증맞은 코로
            <br />
            동물등록을 <strong className="blue">간편하게</strong>
          </Typography>
          <Typography variant="h5" my={3}>
            스마트폰 화면에 반려견의 코가 보이면
            <br />
            인공지능이 코를 인식하고 자동 촬영 진행!
          </Typography>
          <img className="section4-img" src={img_1} alt="" />
        </Box>

        <div className="carousel-item">
          <Typography variant="h5" my={3}>
            <strong className="blue">프로필카드</strong>
          </Typography>
          <Typography variant="h3" className="bold">
            소중한 우리아이
            <br />
            정보를 <strong className="blue">한 눈에 확인</strong>
          </Typography>
          <Typography variant="h5" my={3}>
            일상 속에서 필요한 우리 아이의 정보,
            <br /> 비문동물등록증으로 관리하고 친구 · 가족에게 공유까지!
          </Typography>
          <img className="section4-img" src={img_2} alt="" />
        </div>

        <div className="carousel-item">
          <Typography variant="h5" my={3}>
            <strong className="blue">정보확인</strong>
          </Typography>
          <Typography variant="h3" className="bold">
            코 촬영으로
            <br />
            <strong className="blue">간편한 신원확인</strong>
          </Typography>
          <Typography variant="h5" my={3}>
            유실된 강아지의 코를 촬영하면
            <br />
            인공지능 비문패턴 분석을 통해 누구인지 확인 가능!
          </Typography>
          <img className="section4-img" src={img_3} alt="" />
        </div>

        <div className="carousel-item">
          <Typography variant="h5" my={3}>
            <strong className="blue">다이어리</strong>
          </Typography>
          <Typography variant="h3" className="bold">
            차곡차곡 쌓이는
            <br />
            <strong className="blue">우리아이 다이어리</strong>
          </Typography>
          <Typography variant="h5" my={3}>
            눈 깜짝할 사이에 커버리는 우리아이,
            <br />
            평범한 일상을 기록해 특별한 순간으로 만들어보세요!
          </Typography>
          <img className="section4-img" src={img_4} alt="" />
        </div>

        <div className="carousel-item">
          <Typography variant="h5" my={3}>
            <strong className="blue">정보 쏙쏙</strong>
          </Typography>
          <Typography variant="h3" className="bold">
            멍멍박사가 알려주는
            <br />
            <strong className="blue">반려동물 정보</strong>
          </Typography>
          <Typography variant="h5" my={3}>
            반려견과 함께하는 일상에서 궁금했던 모든 것들,
            <br />
            애니퍼피가 멍멍박사로 만들어 드릴게요!
          </Typography>
          <img className="section4-img" src={img_5} alt="" />
        </div>
      </Slider>
      <Box
        mt={2}
        sx={{
          textAlign: "center",
          display: "flex",
          direction: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button className="section4-button" onClick={previous}>
          <img className="section4-button" src={prev_btn} alt="" />
        </button>
        <Typography variant="h5" className="bold" width={"6rem"}>
          {currentStep} / 5
        </Typography>
        <button className="section4-button" onClick={next}>
          <img className="section4-button" src={next_btn} alt="" />
        </button>
      </Box>
    </div>
  );
};

export default Section4Slider;
