import Main from "./pages/Main";
import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import anipuppy_thumb from "./resources/images/anipuppy_thumb.png";
import sandbox from "./resources/images/이용자고지.png";

import BrandStory from "./pages/BrandStory";
import Technology from "./pages/Technology";
import FAQ from "./pages/FAQ";

import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";

import { Modal } from "./components/Modal";

import Header from "./components/Header";
import Footer from "./components/Footer";
import NotFound from "./pages/NotFound";
import PrivacyCW from "./pages/PrivacyCW";
import { PrivacyTP } from "./pages/PrivacyTP";
import CTABtn from "./components/CTABtn";
import TermsAndPrivacy from "./pages/TermsAndPrivacy";
import PrivacyGhana from "./pages/PrivacyGhana";
import TermsGhana from "./pages/TermsGhana";
import TermsCW from "./pages/TermsCW";
import TermsTP from "./pages/TermsTP";

function App() {
  const isPc = useMediaQuery({
    query: "(min-width:1024px)",
  });
  const isTablet = useMediaQuery({
    query: "(min-width:767px) and (max-width:1023px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width:767px)",
  });
  const nowMediaQuery = isPc
    ? "pc"
    : isTablet
    ? "tablet"
    : isMobile
    ? "mobile"
    : "";

  const [modalOpen, setModalOpen] = useState(false);
  const [useCTABtn, setUseCTABtn] = useState(false);

  const openCTABtn = () => {
    setUseCTABtn(true);
  };

  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  const clickedModalBtn = () => {
    openModal();
  };

  if (process.env.NODE_ENV === "production") {
    console.log = function no_console() {};
    console.warn = function no_console() {};
  }

  const location = useLocation();
  const { pathname } = useLocation();

  window.location.href.includes("beta") &&
    (pathname === "/"
      ? (window.location.href = "https://www.anipuppy.com/")
      : (window.location.href = "https://www.anipuppy.com" + pathname));

  useEffect(() => {
    if (location.pathname === "/") {
      openCTABtn(true);
    }
    if (location.pathname === "/sandbox") {
      setModalOpen(true);
    }
    if (
      (location.pathname === "/terms") |
      (location.pathname === "/privacy") |
      (location.pathname === "/termscw") |
      (location.pathname === "/privacycw") |
      (location.pathname === "/termstp") |
      (location.pathname === "/privacytp") |
      (location.pathname === "/termsnprivacy") |
      (location.pathname === "/privacy-ghana") |
      (location.pathname === "/terms-ghana")
    ) {
      setHeaderFooterOpen(false);
    } else {
      setHeaderFooterOpen(true);
    }
  }, [location.pathname]);

  const [headerFooterOpen, setHeaderFooterOpen] = useState(true);

  return (
    <div
      className={
        isPc
          ? "App pc"
          : isTablet
          ? "App tablet"
          : isMobile
          ? "App mobile"
          : "App"
      }
    >
      <img
        style={{
          display: "none",
        }}
        src={anipuppy_thumb}
        alt=""
      />
      {headerFooterOpen && (
        <Header
          nowMediaQuery={nowMediaQuery}
          clickedModalBtn={clickedModalBtn}
        />
      )}
      {useCTABtn && nowMediaQuery === "mobile" && <CTABtn />}
      <Routes>
        <Route
          exact
          path="/"
          element={<Main nowMediaQuery={nowMediaQuery} />}
        ></Route>
        <Route exact path="/privacy" element={<Privacy />}></Route>
        <Route exact path="/terms" element={<Terms />}></Route>
        <Route exact path="/privacycw" element={<PrivacyCW />}></Route>
        <Route exact path="/termscw" element={<TermsCW />}></Route>
        <Route exact path="/privacytp" element={<PrivacyTP />}></Route>
        <Route exact path="/termstp" element={<TermsTP />}></Route>
        <Route exact path="/privacy-ghana" element={<PrivacyGhana />}></Route>
        <Route exact path="/terms-ghana" element={<TermsGhana />}></Route>
        <Route
          exact
          path="/termsnprivacy"
          element={<TermsAndPrivacy />}
        ></Route>
        <Route
          exact
          path="/brand-story"
          element={<BrandStory nowMediaQuery={nowMediaQuery} />}
        ></Route>
        <Route
          exact
          path="/tech-story"
          element={<Technology nowMediaQuery={nowMediaQuery} />}
        ></Route>
        <Route
          exact
          path="/faq"
          element={<FAQ nowMediaQuery={nowMediaQuery} />}
        ></Route>
        <Route
          exact
          path="/sandbox"
          element={<Main nowMediaQuery={nowMediaQuery} />}
        ></Route>
        <Route path="/*" element={<Navigate to="/notfound" />}></Route>
        <Route
          path="/notfound"
          element={<NotFound nowMediaQuery={nowMediaQuery} />}
        ></Route>
      </Routes>
      <Modal
        nowMediaQuery={nowMediaQuery}
        open={modalOpen}
        close={closeModal}
        header="　"
      >
        <main>
          <img src={sandbox} className="sandbox" alt="" />
        </main>
      </Modal>
      {headerFooterOpen && <Footer />}
    </div>
  );
}

export default App;
