import React, { useEffect } from "react";

export const TermsCW = () => {
  useEffect(() => {
    const titleElement = document.getElementsByTagName("title")[0];
    titleElement.innerHTML = `창원퍼피: 이용 약관 동의`;
  }, []);
  return (
    <>
      <div className="terms">
        <p>아이싸이랩 서비스(창원퍼피)이용약관</p>
        <p>제1장 총칙</p>
        <p>
          제1조 [목적]
          <br />이 약관은 (주)아이싸이랩이 제공하는 회사 및 회사 관련 제반
          서비스의 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임사항, 기타
          필요한 사항을 규정함을 목적으로 합니다. 본 약관은 PC 통신,
          스마트폰(안드로이드폰, 아이폰 등) 앱 등을 이용하는 전자상거래에
          대해서도 그 성질에 반하지 않는 한 준용됩니다.
        </p>
        <p>
          <br />
          제2조 [정의]
          <br />이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
          <br />
          1) &ldquo;서비스&rdquo;라 함은 접속 가능한 유. 무선 단말기의 종류와는
          상관없이 이용 가능한 '회사'가 제공하는 모든 '서비스'를 의미합니다.
          <br />
          2) &ldquo;이용자&rdquo;라 함은 회사 서비스에 접속하여 이 약관에 따라
          회사에서 제공하는 &ldquo;콘텐츠&rdquo; 및 제반서비스를 이용하는 회원
          및 비회원을 말합니다.
          <br />
          3) &ldquo;회원&rdquo;이라 함은 &ldquo;아이싸이랩&rdquo;와(과)
          이용계약을 체결하고 &ldquo;이용자&rdquo; 아이디(ID)를 부여받은
          &ldquo;이용자&rdquo;로서 &ldquo;아이싸이랩&rdquo;의 정보를 지속적으로
          제공받으며 &ldquo;아이싸이랩&rdquo;이(가) 제공하는 서비스를 지속적으로
          이용할 수 있는 자를 말합니다.
          <br />
          4) &ldquo;아이디(ID)&rdquo;라 함은 &ldquo;회원&rdquo;의 식별과
          서비스이용을 위하여 &ldquo;회원&rdquo;이 정하고
          &ldquo;아이싸이랩&rdquo;이(가) 승인하는 문자 또는 숫자의 조합을
          말합니다.
          <br />
          5) &ldquo;비밀번호(PASSWORD)&rdquo;라 함은 &ldquo;회원&rdquo;이
          부여받은 &ldquo;아이디&rdquo;와 일치되는 &ldquo;회원&rdquo;임을
          확인하고 비밀보호를 위해 &ldquo;회원&rdquo; 자신이 정한 문자 또는
          숫자의 조합을 말합니다.
          <br />
          6) &ldquo;탈퇴&rdquo;라 함은 회사 또는 회원이 서비스 개통 후
          이용계약을 해약하는 것을 의미합니다.
          <br />
          7) &ldquo;콘텐츠&rdquo;라 함은 회사, 회원이 부호, 문자, 음성, 음향,
          이미지 또는 동영상 등으로 표현된 글, 사진, 동영상, 음원, 및 각종
          파일과 링크 등을 편집하여 회사가 운영하는 자료 또는 정보로서, 그 보존
          및 이용에 있어서 효용을 높일 수 있도록 전자적 형태로 제작 또는 처리된
          것을 말합니다.
          <br />이 약관은 관계 법령 등에서 정하는 바에 따릅니다.
        </p>
        <p>
          <br />제 3 조 [약관 외 준칙]
          <br />
          운영자는 필요한 경우 별도로 운영정책을 공지 안내할 수 있으며, 본
          약관과 운영정책이 중첩될 경우 운영정책이 우선 적용됩니다.
        </p>
        <p>
          <br />제 4조 [약관의 명시, 효력 및 변경]
          <br />
          1.이 약관은 회사가 제공하는 온라인상의 인터넷 서비스(이하 '서비스')의
          이용약관으로 이용자에게 공시함으로써 효력이 발생합니다.
          <br />
          2.회사는 『전자상거래 등에서의 소비자보호에 관한 법률』, 『약관의
          규제에 관한 법률』, 『전자문서 및 전자거래기본법』,
          『전자금융거래법』, 『전자서명법』, 『정보통신망 이용촉진 및 정보보호
          등에 관한 법률』, 『소비자기본법』 등 관련법령을 위배하지 않는
          범위에서 이 약관을 개정할 수 있습니다
          <br />
          3.개정된 약관에 이용자의 권리 또는 의무 등 중요한 규정의 개정이 있는
          경우 사전에 공지합니다. 다만, 회원에게 불리한 약관의 개정의 경우에는
          전자우편, 전자쪽지, 로그인시 동의창 등의 전자적 수단을 통해 따로
          명확히 통지하도록 합니다.
          <br />
          4.회사가 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게 7일 기간
          내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을
          명확하게 공지 또는 통지하였음에도 회원이 명시적으로 거부의 의사표시를
          하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.
          <br />
          5.이 약관에 동의하는 것은 정기적으로 웹을 방문하여 약관의 변경사항을
          확인하는 것에 동의함을 의미합니다. 변경된 약관에 대한 정보를 알지 못해
          발생하는 이용자의 피해는 회사에서 책임지지 않습니다.
          <br />
          6.회원이 변경된 약관에 동의하지 않을 경우 회사는 해당 회원의
          탈퇴(해지)를 요청할 수 있습니다.
        </p>
        <p>
          <br />제 5 조 [회원 계약의 체결]
          <br />
          가입신청인이 본 약관을 읽고 &ldquo;동의&rdquo; 버튼을 누르거나
          &ldquo;확인&rdquo; 등에 체크하는 방법을 취한 경우 본 약관에 동의한
          것으로 간주합니다.
          <br />
          아이싸이랩은 제 1항의 가입신청과 관련하여 가입신청인에게 전문기관을
          통한 실명확인 및 본인인증을 요청할 수 있습니다.
          <br />
          가입신청인은 아이싸이랩의 요청에 따라 본인의 개인정보 중 필수 항목
          (성명, 주소, 아이디, 비밀번호, 전화번호, 전자우편 주소)를
          제공하여야합니다.
          <br />
          가입신청인은 이용하려는 아이싸이랩의 서비스에 따라 반려동물 정보 중
          필수 항목(사진, 품종, 나이, 성별, 모색, 중성화 여부등)을 제공하여야
          합니다.
          <br />
          아이싸이랩은 가입신청인의 서비스 이용 신청에 대해 승낙함을 원칙으로
          합니다. 다만 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지
          않거나 사후에 회원계약을 해지할 수 있습니다.
          <br />
          1) ​실명이 아니거나 타인의 명의를 사용한 경우
          <br />
          2) ​가입신청인이 회원가입일 이전에 이 약관에 의하여 회원자격을 상실한
          적이 있는 경우, 단 회사의 회원 재가입 승낙을 얻은 경우에는 예외로 함
          <br />
          3) ​허위 정보를 기재하거나 회사가 제시한 필수 항목을 기재하지 않은
          경우
          <br />
          4) ​이용자가 부정한 용도로 서비스를 이용하는 경우
          <br />
          5) ​관계법령에 위배되거나 선량한 풍속 기타 사회질서를 저해할 수 있는
          목적으로 신청한 경우
          <br />
          6) ​기타 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 제반
          사항을 위반하여 신청한 경우
          <br />
          7) ​회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가
          있는 경우에는 승낙을 유보할 수 있습니다.
          <br />
          8) ​제5항 또는 제 6항에 따라 회원가입신청의 승낙을 하지 아니하거나
          유보한 경우, 회사는 원칙적으로 가입신청인에게 그 사실을 알리도록
          합니다.
          <br />
          회원계약의 체결 시기는 가입 신청인이 제1항과 같이 동의한 후, 회사가
          정한 온라인 회원가입 신청서에 회원 ID를 포함한 필수사항을 입력하고,
          &ldquo;가입하기&rdquo; 내지 &ldquo;확인&rdquo; 버튼을 누른 시점으로
          합니다.
        </p>
        <p>
          <br />제 6조 [회원정보의 변경]
          <br />① &ldquo;회원&rdquo;은 개인정보관리화면을 통하여 언제든지 자신의
          개인정보를 열람하고 수정할 수 있습니다.
          <br />② &ldquo;회원&rdquo;은 회원가입신청 시 기재한 사항이 변경되었을
          경우 온라인으로 수정을 하거나 전자우편 기타 방법으로
          &ldquo;아이싸이랩&rdquo;에 대하여 그 변경사항을 알려야 합니다.
          <br />③ 제2항의 변경사항을 &ldquo;아이싸이랩&rdquo;에 알리지 않아
          발생한 불이익에 대하여 &ldquo;아이싸이랩&rdquo;은(는) 책임지지
          않습니다.
        </p>
        <p>
          <br />제 7조 [서비스의 이용]
          <br />
          회사는 회원의 이용신청을 승낙할 때부터 즉시 서비스를 개시합니다. 단,
          회사의 업무적 또는 기술적 장애로 인하여 서비스를 개시하지 못하는 경우,
          서비스 상에 공지하거나 회원에게 즉시 이를 통지합니다.
          <br />
          서비스의 이용은 특별한 지장이 없는 한 연중무휴 1일 24시간을 원칙으로
          합니다. 다만 시스템 정기 점검 및 증설, 교체 등 운영상의 목적으로
          회사가 정한 기간에는 서비스의 전부 또는 일부가 중지될 수 있습니다.
          이러한 경우 회사는 사전 공지 합니다.
          <br />
          회사는 긴급한 시스템 점검 및 증설, 교체, 국가 비상사태, 정전, 서비스
          설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스가 불가능할
          경우 사전 공지나 예고 없이 서비스를 일시 중단할 수 있습니다.
          <br />
          회사는 서비스의 이용 가능한 시간을 별도로 정할 수 있으며, 이 경우 그
          내용을 사전에 공지합니다. 단 회사가 통제할 수 없는 사유로 발생한
          서비스 중단에 대하여 사전 공지가 불가능한 경우에는 예외로 합니다.
          <br />
          회사는 모바일 단말기(아이폰, 안드로이드 OS 단말기 등) 전용
          어플리케이션을 제공하며, 회원을 회사에서 제공하는 범위 내의 모바일
          단말기에서 전용 어플리케이션을 다운로드 및 설치하여 본 서비스를 이용할
          수 있습니다. 모바일 단말기의 종류 및 통신사에 따라 어플리케이션의
          구성과 기능에는 차이가 있을 수 있습니다.
        </p>
        <p>
          <br />제 8조 [서비스의 내용]
          <br />
          &ldquo;회사&rdquo;는 &ldquo;회원&rdquo;이 직접 입력/등록한 회원의
          정보와 반려동물의 정보를 이용합니다.
          <br />
          &ldquo;회원&rdquo;의 정보와 반려동물의 정보는 해당 스마트폰 등에서
          고객이 직접 입력하여 제공합니다.
          <br />
          &ldquo;회사&rdquo;는 "회원"이 직접 입력한 반려동물의 정보를
          "반려동물서비스제공자"에게 제공할 수 있습니다.
          <br />
          아이싸이랩이 제공하는 구체적인 서비스 내용은 아래와 같습니다.
          <br />
          아이싸이랩 스마트폰 어플리케이션
          <br />
          비문 인식 서비스 : 회원이 등록한 반려동물의 비문을 등록, 인증하는
          서비스
          <br />
          콘텐츠 서비스 : 아이싸이랩 또는 반려동물 관련 업체가 제공하는 뉴스,
          반려동물 상식정보등의 다양한 콘텐츠를 무료로 열람 가능.
          <br />
          보호동물 조회 및 입양정보 서비스 : 동물보호시스템 API가 제공하는
          유기‧유실동물 목록을 조회하고 입양 문의 서비스를 제공
          <br />
          반려동물 커뮤니티 서비스
          <br />
          반려동물 매장 정보 서비스 : 회원으로부터 제공받은 위치정보를 이용하여
          이용자 주변의 반려동물 관련 사항을 제공합니다.
        </p>
        <p>
          <br />제 9조 [개인정보보호]
          <br />
          회사는 회원의 개인정보 보호를 매우 중요시하며, &ldquo;정보통신망
          이용촉진 및 정보보호 등에 관한 법률&rdquo;(이하
          &ldquo;정보통신망법&rdquo;)등 관계 법령이 정하는 바에 따라 회원의
          개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에
          대해서는 관련법 및 회사의 개인정보취급방침이 적용됩니다.
          <br />
          회원(휴면 회원 포함)이 제공한 개인정보는 서비스 가입기간 동안 이용,
          보관함을 원칙으로 합니다. 단, 서비스 탈퇴 시 제공된 모든 개인정보는
          삭제됩니다.
          <br />
          회사는 이용자의 개인 식별이 가능한 개인정보를 수집하는 때에는 당해
          이용자의 동의를 받습니다.
          <br />
          회사는 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운
          이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용‧제공
          단계에서 당해 이용자에게 그 목적을 고지하고 동의를 받습니다. 다만,
          관련 법령에 따라 정함이 있는 경우에는 예외로 합니다. <br />
          회사가 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는
          &ldquo;개인정보&rdquo;관리책임자의 신원(소속, 성명 및 전화번호 기타
          연락처), 정보의 수집목적 및 이용목적, 제3자에 대한
          정보제공관련사항(제공받는 자, 제공목적 및 제공할 정보의 내용)등에
          관하여 정보통신망이용촉진 및 정보보호 등에 관한 법률 제22조 제2항이
          규정한 사항을 명시하고 고지하여야 하며 이용자는 언제든지 이 동의를
          철회할 수 있습니다.
          <br />
          회원은 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및 오류
          정정을 요구할 수 있으며 회사는 이에 대해 지체없이 필요한 조치를 취할
          의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 회사는 그 오류를
          정정할 때까지 당해 &ldquo;개인정보&rdquo;를 이용하지 않습니다.
          <br />
          회사는 개인정보보호를 위하여 관리자를 한정하여 그 수를 최소화하며,
          회원의 개인정보분실, 도난, 유출, 변조 등으로 인한 이용자의 손해에
          대하여 책임을 집니다.
          <br />
          회사 또는 그로부터 개인정보를 제공받은 자는 이용자가 동의한 범위
          내에서 개인정보를 사용할 수 있으며, 목적이 달성된 경우에는 당해
          개인정보를 지체 없이 파기합니다.
        </p>
        <p>
          <br />
          제10조 [개인위치정보의 보호]
          <br />
          회사는 &ldquo;위치정보의 보호 및 이용 등에 관한 법률&rdquo; 등 관계
          법렵을 정하는 바에 따라 회원의 개인 위치정보를 보호하기 위해
          노력합니다. 개인위치정보의 보호 및 사용에 관해서는 관련법 및 회사의
          개인정보취급방침이 적용됩니다.
        </p>
        <p>
          <br />
          제11조 회사의 의무
          <br />
          회사는 법령과 이 약관이 정하는 권리의 행사와 의무의 이행을 신의에 좇아
          성실하게 하여야 합니다.
          <br />
          회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보 보호를 위해
          보안 시스템을 갖추어야 하며 개인정보취급방침을 공시하고 준수합니다.
          <br />
          회사는 서비스이용과 관련하여 회원으로부터 제기된 의견이나 불만이
          정당하다고 인정할 경우에는 이를 처리하여야 합니다. 회원이 제기한
          의견이나 불만사항에 대해서는 연락 가능한 수단을 통하여 회원에게
          처리과정 및 결과를 전달합니다. <br />
          회사는 이용자가 원하지 않는 영리 목적의 광고성 전자 우편을 발송하지
          않습니다.
        </p>
        <p>
          <br />
          제12조 회원의 의무
          <br />
          이용자는 회원가입 신청 또는 회원정보 변경 시 본인에 대한 정확한 정보로
          모든 사항을 사실에 근거하여 작성하여야 하며, 허위 또는 타인의 정보로
          등록할 경우 일체의 권리를 주장할 수 없습니다. <br />
          회원은 관계법령, 이 약관의 규정, 이용안내 및 주의사항 등 회사가
          공지하는 사항을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를
          하여서는 안 됩니다. <br />제 10조의 경우를 제외한 ID와 비밀번호에 관한
          관리 책임을 회원에게 있습니다. 회원은 자신의 아이디와 패스워드가
          부정하게 사용되었다는 사실을 발견한 경우에는 즉시 회사에 신고하여야
          하며, 신고를 하지 않아서 발생하는 모든 결과에 대한 책임은 회원에게
          있습니다. <br />
          회원은 회사의 사전 승낙 없이 서비스를 이용하여 제 3자를 위한
          광고활동을 할 수 없으며, 그 활동의 결과에 대해 회사는 책임을지지
          않습니다. 또한 회원은 이와 같은 영업활동으로 회사가 손해를 입은 경우,
          회원은 회사에 대해 손해배상의무를 지며, 회사는 해당 회원에 대해 서비스
          이용제한 및 적법한 절차를 거쳐 손해배상 등을 청구할 수 있습니다.
        </p>
        <p>
          <br />
          제13조 [게시물의 관리]
          <br />
          게시물이라 함은 회사의 서비스에 업로드 된 콘텐츠 및 콘텐츠를 구성하는
          각종 파일과 링크. 회원들의 댓글 등을 포함하는 정보를 의미합니다.
          <br />
          아이싸이랩이 작성한 저작물에 대한 저작권 및 기타 지적 재산권은
          아이싸이랩에 귀속합니다. <br />
          회원이 작성한 게시물에 대한 저작권 및 모든 책임은 이를 게시한 회원에게
          있습니다. 단, 회사는 회원이 게시하거나 등록하는 게시물의 내용이 다음
          각 호에 해당한다고 판단하는 경우 해당 게시물을 사전 통지 없이 삭제
          또는 임시 조치(블라인드) 할 수 있습니다. <br />
          1) 회사, 다른 회원 또는 제 3자를 비방하거나 명예를 손상시키는 내용인
          경우
          <br />
          2) 공공질서 및 미풍양속에 위반
          <br />
          3) 범죄적 행위에 결부된다고 인정되는 경우 <br />
          4) 회사의 저작권, 제 3자의 저작권 등 기타 권리를 침해하는 내용인 경우
          <br />
          5) 회원이 사이트와 게시판에 음란물을 게재하거나 음란사이트를 링크하는
          경우
          <br />
          6) 회사로부터 사전 승인 받지 아니한 상업광고, 판촉 내용을 게시하는
          경우
        </p>
        <p>
          <br />제 14조 [이용계약의 해지 및 이용제한]
          <br />
          회원이 이용계약을 해지하고자 하는 때에는 회원 본인이 온라인으로
          신청하거나 고객센터를 통해 해지할 수 있으며, 회사는 관련법 등이 정하는
          바에 따라 이를 즉시 처리합니다.
          <br />
          회원이 계약을 해지함과 동시에 회원의 개인정보 및 회원이 회사에 작성한
          게시물은 모두 소멸됩니다.
          <br />
          회사는 회원이 제 12조 등 이 약관의 내용을 위반하거나 또는 다음 각 호에
          해당되는 경우 이용계약을 해지하거나 기간을 정하여 아이싸이랩 서비스
          이용을 중지할 수 있습니다. <br />
          1) 회원이 서비스와 관련하여 회사 또는 제 3자에게 직‧간접적으로
          재산적‧비재산적 손해를 가한 경우
          <br />
          2) 회원이 회사 또는 타인의 권리나 명예, 신용 기타 정당한 이익을
          침해하여 회사의 영업을 침해한 경우
          <br />
          3) 회원이 이 약관에 위배되는 행위를 하거나 약관에서 정한 해지사유가
          발생한 경우
          <br />
          4) 기타 정상적인 아이싸이랩 서비스 운영에 방해가 되는 경우
        </p>
        <p>
          <br />제 15조[이용제한 등의 통지 및 그 해제]
          <br />
          회사는 회원의 아이싸이랩 서비스 이용을 제한 또는 중지하고자 하는
          경우에는 그 사유 및 일시, 기간을 정하여 서비스 화면에 게시하거나
          회원에게 통지합니다. 단, 회사가 긴급하게 이용을 정지할 필요가 있다고
          인정되는 경우나 회원의 귀책사유로 인하여 통지할 필요가 없는 경우에는
          그러하지 아니합니다.
          <br />
          회사의 아이싸이랩 서비스 이용제한을 통보 받은 회원은 이용제한에 대하여
          이의가 있을 경우에는 그 사유를 명시하여 회사에 이의 신청을 할 수
          있습니다. 이 경우 회사는 이의 신청에 대한 확인기간까지 이용제한을 일시
          연기할 수 있으며 그 결과를 회원에게 통지합니다.
          <br />
          회사는 이용제한 기간 중에 이용제한 사유가 해소된 것이 확인된 경우에는
          지체 없이 이용제한 조치를 해제합니다.
        </p>
        <p>
          제 16조 [손해배상]
          <br />
          회원은 회사의 다음 각 호에 해당하는 행위로 손해를 입은 경우에 회사에
          대해 손해배상을 청구할 수 있습니다. 이 경우 회원이 회사의 고의 또는
          과실이 있음을 직접 입증하여야 합니다. <br />
          1) 법령에서 허용하는 경우를 제외하고 이용자 동의 없이
          &ldquo;고객&rdquo;의 정보를 수집, 이용하는 행위
          <br />
          2) 회원의 정보의 누출, 변조, 훼손 등의 행위
          <br />
          회사는 서비스에서 무료로 제공하는 서비스의 이용과 관련하여
          개인정보보호정책에서 정하는 내용에 해당하지 않는 사항에 대하여는
          어떠한 손해도 책임을 지지 않습니다.
        </p>
        <p>
          <br />제 17조 [면책조항]
          <br />
          회사의 서비스로부터 회원이 받은 손해가 천재지변 등 불가항력적이거나
          회원의 고의 또는 과실로 인하여 발생한 때에는 서비스 제공에 대한 책임이
          면제됩니다.
          <br />
          회사는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로
          제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.
          <br />
          회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로
          발생한 손해에 대한 책임이 면제됩니다.
          <br />
          회사는 회원의 귀책사유로 인한 서비스 이용의 장애 또는 손해에 대하여
          책임을 지지 않습니다.
          <br />
          회사는 회원이 아이싸이랩 서비스에 게재한 정보, 자료, 사실의 신뢰도,
          정확성 등 내용에 관하여는 책임을 지지 않습니다.
          <br />
          회사는 회원 상호간 및 회원과 제 3자간 서비스를 매개로 하여 발생한
          분쟁에 개입할 의무가 없으며 이로 인한 손해를 배상할 책임을 지지
          않습니다.
        </p>
        <p>
          <br />제 18조[분쟁의 조정]
          <br />
          회사는 본 서비스 약관에 관련된 분쟁의 당사자 간 협의가 이루어지지
          아니하거나 협의를 할 수 없는 경우에는 전기통신기본법의 규정에 따라
          방송통신위원회에 재정을 신청하거나 정보통신망 이용촉진 및 정보 보호
          등에 관한 법률의 규정에 의한 개인정보 분쟁 조정 위원회에 조정을 신청
          할 수 있습니다.
        </p>
        <p>
          <br />
          부칙 <br />이 약관은 2023년 5월 19일부터 적용됩니다.
        </p>
      </div>
    </>
  );
};
export default TermsCW;
