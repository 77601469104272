import React from "react";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

import section1_slogan from "../resources/images/bs/section1_slogan.png";
import img_story1 from "../resources/images/bs/story_img1.jpg";
import img_story2 from "../resources/images/bs/story_img2.jpg";
import img_story3 from "../resources/images/bs/story_img3.jpg";
import slogan_bottom from "../resources/images/bs/slogan_bottom.png";
import keep_going_brown from "../resources/images/bs/keep_going_brown.png";
import hand_graphic from "../resources/images/bs/hand_graphic.png";
import dog_hand_graphic from "../resources/images/bs/dog_hand_graphic.png";
import slogan_bottom_text from "../resources/images/bs/함께해요우리.png";
import { Box, Typography, Stack } from "@mui/material";
import img_1 from "../resources/images/bs/brand-story-img-pc.png";
import shield from "../resources/images/bs/shield.png";

const BrandStory = (props) => {
  const nowMediaQuery = props.nowMediaQuery;
  return (
    <div className="brand_story">
      <section className="front brand_story">
        <Box sx={{ position: "absolute", top: "20%" }}>
          <Typography
            variant="h3"
            className="slogan"
            color="black"
            textAlign="center"
          >
            <strong>
              반려견을
              <br />
              더 안전하게 지켜주는
              <br />
              애니퍼피
            </strong>
          </Typography>

          <Typography
            variant="h5"
            textAlign="center"
            sx={{ mt: 2 }}
            color="black"
          >
            안전하게 함께 걷는 우리의 첫 발자국
          </Typography>
        </Box>
        {nowMediaQuery !== "mobile" ? (
          <Stack
            direction={"column"}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              top: "47%",
            }}
          >
            <img src={shield} alt="img" />
            <Box width="40rem" ml={13}>
              <img className="sub-main-img" src={img_1} alt="img" />
            </Box>
          </Stack>
        ) : (
          <Box
            width="80vw"
            maxWidth={"30rem"}
            sx={{ position: "relative", bottom: "11%" }}
          >
            <img className="sub-main-img" src={img_1} alt="img" />
          </Box>
        )}
      </section>

      {/* section 1 */}
      <section className="contents_section bs1">
        <div>
          <div className="bs1_background_img">
            <Fade
              triggerOnce="true"
              cascade
              duration={3000}
              damping={0.5}
              fraction={0.5}
              direction={"right"}
            >
              <img
                className="graphic hand_graphic"
                src={hand_graphic}
                alt="hand graphic"
              />
            </Fade>
            <Fade
              triggerOnce="true"
              cascade
              duration={3000}
              damping={0.5}
              fraction={0.5}
              direction={"left"}
            >
              <img
                className="graphic dog_hand_graphic"
                src={dog_hand_graphic}
                alt=""
              />
            </Fade>
          </div>
          <div className="bs1_text">
            <Fade triggerOnce="true" cascade duration={1000}>
              <img className="slogan" src={section1_slogan} alt="" />
              <Typography variant="h2" sx={{ mt: 2 }} color="black">
                <strong className="blue">
                  반려동물(伴侶動物)이 행복한 세상
                </strong>
                을 위해
              </Typography>
              <Typography variant="h3" sx={{ mt: 2 }} color="black">
                <strong className="bold">
                  사람들로 인해 버려져 고통받는 동물들과
                  <br />
                  그로인해 고통받는 사회,
                  <br />
                  애니퍼피가 바꿔보려 합니다.
                </strong>
              </Typography>

              <div>
                <p>
                  anipuppy는 Animal(동물)의 Ani와 Puppy(강아지)가 합쳐진
                  이름으로,
                  <br />
                  <span className="line_blue">
                    <strong className="bold">
                      모든 강아지들이 행복한 세상을 만들자
                    </strong>
                    는 목표를 담고 있습니다.
                  </span>
                </p>
                <br />
                <p>
                  모르는 장소에서 길을 잃어버린 반려동물의 입장을
                  생각해보셨나요?
                  <br />
                  가족을 그리워하는 모든 강아지들이 따뜻한 품으로 돌아갈 수
                  있도록,
                </p>
                <br />
                <p>세상의 모든 반려동물이 행복해질 때까지 노력하겠습니다.</p>
                <br />
                <p>감사합니다.</p>
              </div>
            </Fade>
          </div>
        </div>
      </section>

      {/* section 2 */}
      <section className="contents_section bs2">
        <div>
          <Fade triggerOnce="true" cascade duration={500} fraction={0.2}>
            <Typography variant="h3" sx={{ my: 2 }} color="black">
              <strong className="blue">애니퍼피</strong>는 이렇게 시작했어요.
            </Typography>

            <div className="bs_history">
              <div>
                <p>
                  인공지능 시대, 첨단기술을 이용해
                  <br />
                  반려동물 유기·유실률을 어떻게 하면 줄일 수 있을까? <br />
                  고민했어요
                </p>
                {/* 강아지 뒷모습 */}
                <img className="img_story" src={img_story1} alt="" />
              </div>
            </div>
            <div className="bs_history reverse">
              <div>
                <p className="align_right">
                  같은 뜻을 가진 아이싸이랩의 연구진들이 모여
                  <br />
                  2012년부터 연구를 시작했어요
                </p>
                {/* 연구 사진 */}
                <img className="img_story" src={img_story2} alt="study" />
              </div>
            </div>
            <div className="bs_history">
              <div>
                <p>
                  오랜 연구와 실험 끝에 독보적인 비문기술을 개발했고,
                  <br />
                  특허출원과 국내외 정확도 최고 수준으로 검증되었어요
                </p>
                {/* 코사진 */}
                <img
                  className="img_story"
                  src={img_story3}
                  alt="dog nose pattern"
                />
              </div>
            </div>
          </Fade>
        </div>
      </section>
      {/* section 3 */}
      <section className="contents_section bs3">
        <div>
          <Fade
            triggerOnce="true"
            cascade
            duration={2000}
            damping={0.5}
            fraction={0.5}
            // direction={}
          >
            <Typography
              className="font_UL"
              variant="h3"
              sx={{ my: 2 }}
              color="black"
            >
              <strong className="blue">반려동물</strong>은 우리들의
              <br />
              <strong className="bold">친구이자 가족</strong>입니다.
            </Typography>
            <Typography
              className="font_UL"
              variant="h4"
              sx={{ my: 2 }}
              color="black"
            >
              앞으로 더 재밌고, 가치있으며, 유용한 서비스가 되어
              <br />
              <strong className="bold">
                반려동물이 행복한 세상을 만들겠습니다.
              </strong>
            </Typography>
          </Fade>
        </div>
      </section>

      {/* section 4 */}
      <section className="contents_section keep_going">
        {/* 함께해요 우리 */}
        <img
          className="slogan_bottom_text"
          src={slogan_bottom_text}
          alt="come with us"
        />
        <div>
          <img
            className="keep_going_slogan"
            src={keep_going_brown}
            alt="keep going!"
          />
          <Typography
            className="font_UL"
            variant="h4"
            sx={{ my: 2 }}
            color="black"
          >
            애니퍼피의 반려동물을 위한
            <br />
            연구는
            <strong className="bold"> 아직 끝나지 않았습니다.</strong>
            <br />
            <br />
            앞으로 더 나은 우리의 행복을 원한다면,
            <br />
            애니퍼피와 함께가요!
          </Typography>

          <button className="blue">
            <Link to="/tech-story">
              <strong className="bold">기술 스토리</strong>가 더 궁금하다면?{" "}
              <strong className="bold">CLICK!</strong>
            </Link>
          </button>
        </div>
      </section>

      {/* section 5 */}
      <section className="contents_section bs5">
        <img className="slogan_bottom" src={slogan_bottom} alt="" />
      </section>
    </div>
  );
};

export default BrandStory;
