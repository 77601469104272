import React from "react";
import { Typography } from "@mui/material";

import img1 from "../resources/images/landing-page/section1/section1_1.png";
import img2 from "../resources/images/landing-page/section1/section1_2.png";
import img3 from "../resources/images/landing-page/section1/section1_3.png";
import img4 from "../resources/images/landing-page/section1/section1_4.png";
import img5 from "../resources/images/landing-page/section1/section1_5.png";
import img6 from "../resources/images/landing-page/section1/section1_6.png";
import img7 from "../resources/images/landing-page/section1/section1_7.png";
import img8 from "../resources/images/landing-page/section1/section1_8.png";
import img9 from "../resources/images/landing-page/section1/section1_9.png";
import img10 from "../resources/images/landing-page/section1/section1_10.png";
import InfiniteLoopSlider from "../components/InfiniteLoopSlider";

const Section1 = (props) => {
  const nowMediaQuery = props.nowMediaQuery;

  const sliderItems = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
  ];
  return (
    <section className="section">
      <div className="contents center_align">
        <Typography variant="h3" className="bold">
          애니퍼피가 필요한 순간!
        </Typography>
        <Typography variant="h5" my={5}>
          애니퍼피는 반려동물의 고유한 생체정보(비문)를 <br />
          <span className="blue">스마트폰으로 촬영해 등록</span>하고 누구인지
          찾을 수 있어요.
        </Typography>
        <>
          <InfiniteLoopSlider>
            {sliderItems.map((img, idx) => (
              <div className="Main__best-item" key={`title${idx}`}>
                {nowMediaQuery !== "mobile" && (
                  <div className="Main__best-item-img">
                    <img className="slider_item" src={img} alt="" />
                  </div>
                )}
                {nowMediaQuery === "mobile" && (
                  <div className="Main__best-item-img-mobile">
                    <img className="slider_item" src={img} alt="" />
                  </div>
                )}
              </div>
            ))}
          </InfiniteLoopSlider>
        </>

        <Typography variant="h5" mt={3}>
          이미 동물등록한 경우 비문정보와 연동이 가능해요.
        </Typography>
      </div>
    </section>
  );
};

export default Section1;
