import React from "react";

import img_1 from "../resources/images/landing-page/char1.png";
import img_2 from "../resources/images/landing-page/char2.png";
import img_mobile from "../resources/images/landing-page/char-mobile.png";
import img_qr from "../resources/images/landing-page/app-install-qr.png";

import { Box, Typography, Paper, Stack } from "@mui/material";

const MainSection = (props) => {
  const nowMediaQuery = props.nowMediaQuery;

  return (
    <section className={nowMediaQuery === "mobile" ? "mobile page" : "page"}>
      <div className="main_section background-light-blue">
        <Box sx={{ top: "20%", position: "absolute" }}>
          <Typography
            variant="h1"
            className="slogan bold"
            color="black"
            textAlign="center"
            fontSize={"3rem"}
          >
            동물등록은
            <br />
            애니퍼피로
            <br />
            쉽고 간단하게
          </Typography>

          <Typography
            variant="h5"
            textAlign="center"
            sx={{ mt: 2 }}
            color="black"
          >
            비문 동물등록 앱, 애니퍼피
          </Typography>
        </Box>

        {nowMediaQuery !== "mobile" ? (
          <Stack
            direction={"row"}
            sx={{
              position: "absolute",
              display: "flex",
              alignItems: "center",
              marginBottom: 11,
            }}
          >
            <img className="main-img" src={img_1} alt="img" />

            <Paper
              sx={{
                borderRadius: 5,
                width: "10rem",
                height: "10rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mx: 3,
              }}
              elevation={0}
            >
              <img className="main-img-qr" src={img_qr} alt="img" />
            </Paper>

            <img className="main-img" src={img_2} alt="img" />
          </Stack>
        ) : (
          <Box
            sx={{
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 11,
            }}
          >
            <img className="main-img-mobile" src={img_mobile} alt="img" />
          </Box>
        )}
      </div>
    </section>
  );
};

export default MainSection;
