export const IOS_INSTALL =
  "https://apps.apple.com/kr/app/%EC%95%A0%EB%8B%88%ED%8D%BC%ED%94%BC-%EB%B0%98%EB%A0%A4%EB%8F%99%EB%AC%BC-%EB%B9%84%EB%AC%B8-%EB%8F%99%EB%AC%BC%EB%93%B1%EB%A1%9D-%EC%95%B1/id6449179098";
export const ANDROID_INSTALL =
  "https://play.google.com/store/apps/details?id=com.iscilab.app.anipuppy";
export const NAVER_BLOG_LINK = "https://blog.naver.com/anipuppy_official";
export const MAIL_LINK = "mailto:contact@anipuppy.com";
export const KAKAO_LINK = "http://pf.kakao.com/_xfmxbcb";
export const INSTA_LINK = "https://www.instagram.com/anipuppy_official/";
export const FACEBOOK_LINK =
  "https://www.facebook.com/profile.php?id=100083728467798";
