import React from "react";

import img from "../resources/images/landing-page/section5/shield_img.png";
import { Fade } from "react-awesome-reveal";
import { Typography, Box } from "@mui/material";

const Section5 = (props) => {
  const nowMediaQuery = props.nowMediaQuery;
  return (
    <section className="section">
      <div className="contents process center_align">
        <Fade
          triggerOnce="true"
          direction="down"
          cascade
          duration={1000}
          fraction={0.8}
        >
          <Box sx={{ width: "10rem" }}>
            <img src={img} alt="shield img" className="shield" />
          </Box>
          <Typography variant="h3" className="bold" mt={5}>
            민감한 생체정보,
            <br />
            애니퍼피를 {nowMediaQuery === "mobile" && <br />}믿을 수 있는 이유
          </Typography>
          <Typography variant="h5" mt={2} mb={15}>
            <strong className="blue">99.99%</strong>의 비문 인식 정확도
            <br />
            (공인시험성적서 기준)
          </Typography>
        </Fade>
      </div>
    </section>
  );
};

export default Section5;
