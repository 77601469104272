import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import "../resources/styles/swiper.css";

import MainSection from "./MainSection";
import InstallationGuide from "./InstallationGuide";

import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section4 from "./Section4";

const Main = (props) => {
  const nowMediaQuery = props.nowMediaQuery;

  return (
    <div>
      <MainSection nowMediaQuery={nowMediaQuery} />
      <Section1 nowMediaQuery={nowMediaQuery} />
      <Section2 nowMediaQuery={nowMediaQuery} />
      <Section3 nowMediaQuery={nowMediaQuery} />
      <Section4 nowMediaQuery={nowMediaQuery} />
      <Section5 nowMediaQuery={nowMediaQuery} />
      <Section6 nowMediaQuery={nowMediaQuery} />
      <InstallationGuide nowMediaQuery={nowMediaQuery} />
    </div>
  );
};

export default Main;
