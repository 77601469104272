import React from "react";
import instagram_icon from "../resources/images/icon/insta.png";
import kakao_icon from "../resources/images/icon/kakao.png";
import naver_icon from "../resources/images/icon/naver.png";
import facebook_icon from "../resources/images/icon/facebook.png";
import mail_icon from "../resources/images/icon/mail.png";
import {
  FACEBOOK_LINK,
  INSTA_LINK,
  KAKAO_LINK,
  MAIL_LINK,
  NAVER_BLOG_LINK,
} from "../words";
import { Grid, Link } from "@mui/material";

const SocialLink = () => {
  return (
    <Grid
      className="social_link"
      sx={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      <Link href={INSTA_LINK}>
        <img src={instagram_icon} alt="instagram" className="social_icon" />
      </Link>

      <Link href={KAKAO_LINK}>
        <img src={kakao_icon} alt="kakao friend link" className="social_icon" />
      </Link>

      <Link href={NAVER_BLOG_LINK}>
        <img src={naver_icon} alt="naver blog link" className="social_icon" />
      </Link>

      <Link href={FACEBOOK_LINK}>
        <img src={facebook_icon} alt="facebook link" className="social_icon" />
      </Link>

      <Link href={MAIL_LINK}>
        <img src={mail_icon} alt="anipuppy mail" className="social_icon" />
      </Link>
    </Grid>
  );
};

export default SocialLink;
