const InfiniteLoopSlider = ({ children, style }) => {
  return (
    <div className="InfiniteLoop__slider">
      <div className={`InfiniteLoop__inner`} style={style}>
        {children}
        {children}
      </div>
    </div>
  );
};

export default InfiniteLoopSlider;
