import React, { useState } from "react";
import img_question from "../resources/images/faq/Q.png";
import img_answer from "../resources/images/faq/A.png";
import arrow from "../resources/images/faq/arrow.png";

const FaqContents = (props) => {
  const [toggleBtn, setToggleBtn] = useState(false);

  const handleClickEvent = () => {
    setToggleBtn((toggleBtn) => !toggleBtn);
  };

  return (
    <div className={props.isLast ? "faq_contents is_last" : "faq_contents"}>
      <div
        onClick={handleClickEvent}
        className={
          toggleBtn
            ? "question box on "
            : props.isLast
            ? "question box off is_last"
            : "question box off"
        }
      >
        <div className="question_left">
          <img className="icon" src={img_question} alt="question" />
          <div className="question_text text">{props.question}</div>
        </div>
        <button className="faq_arrow_btn">
          <img
            className={toggleBtn ? "icon toggle" : "icon toggle show"}
            src={arrow}
            alt="toggle button"
          />
        </button>
      </div>
      <div className={toggleBtn ? "show-menu" : "hide-menu"}>
        <div className="answer box">
          <img className="icon" src={img_answer} alt="question" />
          <div className="answer_text text">{props.answer}</div>
        </div>
      </div>
    </div>
  );
};

export default FaqContents;
