import React from "react";
import AppInstallBtn from "../components/AppInstallBtn";
import img from "../resources/images/landing-page/InstallationGuide/동물등록증.png";
import { Box, Stack, Typography } from "@mui/material";
const InstallationGuide = (props) => {
  return (
    <section id="install" className="contents section background-light-blue">
      <Stack
        container
        mt={3}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ width: "90%" }}
        textAlign={"center"}
      >
        <Typography variant="h5" className="bold blue" font>
          세상의 모든 반려동물이 더욱 행복해질때까지!
        </Typography>
        <Typography variant="h3" className="bold" my={3}>
          반려동물이라면
          <br />꼭 필요한 동물등록,
          <br />
          <strong className="blue">애니퍼피</strong>로 시작하세요
        </Typography>
        <AppInstallBtn />
        <Box my={6}>
          <img className="id-card-img" src={img} alt="" />
        </Box>
      </Stack>
    </section>
  );
};

export default InstallationGuide;
