/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Slider from "react-slick";

import img_icon1 from "../resources/images/tech/아이콘01.png";
import img_icon2 from "../resources/images/tech/아이콘02.png";
import img_icon3 from "../resources/images/tech/아이콘03_1.png";
import img_icon4 from "../resources/images/tech/아이콘04.png";
import img_c_logo1 from "../resources/images/tech/하단 로고01.png";
import img_c_logo2 from "../resources/images/tech/하단 로고02.png";
import img_c_logo3 from "../resources/images/tech/하단 로고03.png";
import img_c_logo4 from "../resources/images/tech/하단 로고04.png";
import img_grey_line from "../resources/images/tech/회색라인.png";

import img_1 from "../resources/images/tech/tech-story-img-pc.png";
import img_1_m from "../resources/images/tech/tech-story-img-mobile.png";

import { Box, Typography, Stack } from "@mui/material";

import TechStorySlider from "../components/TechStorySlider";

function Technology(props) {
  const nowMediaQuery = props.nowMediaQuery;
  var banner_settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    pauseOnHover: false,
    swipeToSlide: false,
  };

  return (
    <div className="technology">
      <section className="front tech_story">
        <Box sx={{ position: "absolute", top: "20%", zIndex: 1 }}>
          <Typography
            variant="h3"
            className="slogan"
            color="black"
            textAlign="center"
          >
            <strong>
              전문적으로
              <br />
              편리하게 시작하는
              <br />
              애니퍼피만의 기술
            </strong>
          </Typography>

          <Typography
            variant="h5"
            textAlign="center"
            sx={{ mt: 2 }}
            color="black"
          >
            애니퍼피가 제공하는 특별한 기술
          </Typography>
        </Box>
        {nowMediaQuery !== "mobile" ? (
          <Stack
            direction={"column"}
            sx={{
              display: "flex",
              alignSelf: "end",
              marginBottom: 11,
            }}
          >
            <Box width="45rem" mr={3}>
              <img className="sub-main-img" src={img_1} alt="img" />
            </Box>
          </Stack>
        ) : (
          <Box
            maxWidth={"30rem"}
            width="80vw"
            sx={{
              display: "flex",
              alignSelf: "end",
              marginBottom: 11,
              zIndex: 0,
            }}
          >
            <img className="sub-main-img" src={img_1_m} alt="img" />
          </Box>
        )}
      </section>

      <section className="contents_section tech_story">
        <div className="tech_history_carousel_div">
          <div className="tech_history_header">
            <p className="blue">
              <strong>History</strong>
            </p>
            <h2>
              AI 기술을 활용한
              <br />
              비문 인식 기술 스토리
            </h2>
          </div>
          {nowMediaQuery === "mobile" ? (
            <TechStorySlider nowMediaQuery={nowMediaQuery} slidesToShow={1} />
          ) : (
            <TechStorySlider nowMediaQuery={nowMediaQuery} slidesToShow={3} />
          )}
        </div>
        <img className="img_grey_line" src={img_grey_line} alt="" />
        <div className="best">
          <h2 className="best_title">
            비문 기술을 통한 {nowMediaQuery === "mobile" && <br />}반려동물의{" "}
            {nowMediaQuery !== "mobile" && <br />}
            개체 인식은 {nowMediaQuery === "mobile" && <br />}
            <strong className="blue">애니퍼피</strong>가 세계 최고입니다.
          </h2>
          <ul className="best_tech_ul">
            <li>
              <div>
                <img className="best_tech_li_img" src={img_icon1} alt="" />
                <p>
                  <strong>Convenience</strong>
                </p>
                <p className="font_UL best_tech_li_text">
                  스마트폰으로
                  <br />
                  편리하게 사용
                </p>
              </div>
              <div>
                <img className="best_tech_li_img" src={img_icon2} alt="" />
                <p>
                  <strong>Recognition</strong>
                </p>
                <p className=" font_UL best_tech_li_text">
                  독보적인 비문인식
                  <br />
                  99.99% 정확도
                </p>
              </div>
            </li>
            <li>
              <div>
                <img className="best_tech_li_img" src={img_icon3} alt="" />
                <p>
                  <strong>Simplify</strong>
                </p>
                <p className=" font_UL best_tech_li_text">
                  등록 시간 및
                  <br />
                  등록 절차 최소화
                </p>
              </div>
              <div>
                <img className="best_tech_li_img" src={img_icon4} alt="" />
                <p>
                  <strong>TTA PG505</strong>
                </p>
                <p className=" font_UL best_tech_li_text">
                  비문기반 반려동물
                  <br />
                  개체인식 표준화 주도
                </p>
              </div>
            </li>
          </ul>
        </div>
        <img className="img_grey_line" src={img_grey_line} alt="" />
        <div className="partners_banner">
          <Slider {...banner_settings}>
            <img className="partners_item" src={img_c_logo1} alt="" />
            <img className="partners_item" src={img_c_logo2} alt="" />
            <img className="partners_item" src={img_c_logo3} alt="" />
            <img className="partners_item" src={img_c_logo4} alt="" />
            <img className="partners_item" src={img_c_logo1} alt="" />
            <img className="partners_item" src={img_c_logo2} alt="" />
            <img className="partners_item" src={img_c_logo3} alt="" />
            <img className="partners_item" src={img_c_logo4} alt="" />
          </Slider>
        </div>
      </section>
    </div>
  );
}

export default Technology;
