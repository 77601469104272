import { React, useState, useEffect } from "react";

import { Box, Typography, Stack } from "@mui/material";
import axios from "axios";
import FaqContents from "../components/FaqContents";
import img_1 from "../resources/images/faq/faq-img-pc.png";

function FAQ(props) {
  const nowMediaQuery = props.nowMediaQuery;
  const [faq, setFaq] = useState([
    {
      title: "Lorem cupidatat sit non qui occaecat deserunt laborum.",
      content:
        "Ut ex est labore commodo aliqua officia occaecat commodo quis eu. \n\n Aute sint mollit fugiat eu do aliquip nulla ut incididunt dolore id id. Velit aute duis fugiat elit do fugiat enim Lorem non non. Dolor pariatur ex consequat dolor dolore eu labore proident esse elit tempor culpa adipisicing. Ullamco ad nisi deserunt labore elit officia quis culpa incididunt. Proident ad in laboris minim reprehenderit non et nostrud elit pariatur nulla dolor. Do laboris aliquip velit officia.\n Dolor ea tempor ex ullamco aute. Reprehenderit ad deserunt occaecat aliqua. Officia anim amet dolore anim ex enim aute labore occaecat excepteur cupidatat in est cupidatat. Est eu dolore elit nisi pariatur elit incididunt adipisicing reprehenderit deserunt fugiat. Irure nulla deserunt esse incididunt fugiat dolor Lorem quis laboris cupidatat excepteur incididunt. Cupidatat deserunt qui occaecat qui ad Lorem sit duis in qui. Ex id aute aliqua quis qui proident sint. \n Lorem elit est excepteur est deserunt magna excepteur aute nulla pariatur do culpa elit dolor. Nisi consectetur velit proident mollit. Aliqua in consectetur laborum commodo consequat eu in pariatur exercitation nostrud officia. \n Id cillum ex sint voluptate est sint sit commodo anim. Laboris consectetur cupidatat pariatur minim eu nulla Lorem do aute do in nostrud. Laborum sit occaecat irure dolore ullamco anim deserunt sit in eiusmod. Excepteur proident esse ea do. Veniam id eu culpa esse elit duis fugiat do. Sit ullamco in consectetur voluptate laboris minim.",
      lang: "init_ko",
    },
  ]);

  useEffect(() => {
    axios
      .get("/api/faq")
      .then((response) => {
        typeof response.data === "object" && setFaq(response.data);
      })
      .catch((error) => {});
  }, []);

  const faqList = faq.map((data, idx) =>
    idx !== faq.length - 1 ? (
      <FaqContents
        key={idx}
        question={data.title}
        answer={data.content}
        isLast={false}
      />
    ) : (
      <FaqContents
        key={idx}
        question={data.title}
        answer={data.content}
        isLast={true}
      />
    )
  );

  return (
    <div className={(props.nowMediaQuery, "FAQ")}>
      <section className="front faq">
        <Box sx={{ position: "absolute", top: "20%", zIndex: 1 }}>
          <Typography
            variant="h3"
            className="slogan"
            color="black"
            textAlign="center"
          >
            <strong>
              궁금한 점을
              <br />
              빠르고 간편하게
              <br />
              해결하세요
            </strong>
          </Typography>

          <Typography
            variant="h5"
            textAlign="center"
            sx={{ mt: 2 }}
            color="black"
          >
            애니퍼피에서 자주 묻는 질문을 확인하세요
          </Typography>
        </Box>
        {nowMediaQuery !== "mobile" ? (
          <Stack
            direction={"column"}
            sx={{
              display: "flex",
              alignSelf: "end",
              marginBottom: 11,
            }}
          >
            <Box width="35rem">
              <img className="sub-main-img" src={img_1} alt="img" />
            </Box>
          </Stack>
        ) : (
          <Box
            width="80vw"
            maxWidth={"30rem"}
            sx={{
              display: "flex",
              alignSelf: "end",
              marginBottom: 11,
            }}
          >
            <img className="sub-main-img" src={img_1} alt="img" />
          </Box>
        )}
      </section>

      <section className="faq_contents_section">
        <div>
          <Typography variant="h3" className="slogan" color="black">
            자주 묻는 질문
          </Typography>
          <p className="dividing_line"></p>
          <div className="faq_list">{faqList}</div>
        </div>
      </section>
    </div>
  );
}

export default FAQ;
