import React from "react";

export const Privacy = () => {
  return (
    <>
      <div className="privacy">
        <p>개인정보 처리방침</p>
        <p>
          &lt; (주)아이싸이랩 &gt;('https://anipuppy.com/'이하 '애니퍼피')은(는)
          「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와
          관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과
          같이 개인정보 처리방침을 수립&middot;공개합니다.
        </p>
        <p>&nbsp;</p>
        <p>제1조(개인정보의 처리목적)</p>
        <p>
          &lt; (주)아이싸이랩 &gt;(이)가 개인정보 보호법 제32조에 따라
          등록․공개하는 개인정보파일의 처리목적은 다음과 같습니다.
        </p>
        <p>
          1. 개인정보 파일명 : 애니퍼피 어플리케이션 회원 정보
          <br />
          개인정보의 처리목적 : 추후 반려동물 관련 업체 예약시스템기능과의 연동
          및 유기,유실견을 찾아주는 목적입니다.
          <br />
          수집방법 : 회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에
          대해 동의를 하고 직접 정보를 입력합니다.
          <br />
          보유근거 : 회원가입 및 반려동물 등록시 정보주체의 이용자 동의를
          받습니다.
          <br />
          보유기간 : 이용자의 서비스 마지막 이용일, 회원 탈퇴 후 1년이 되는 시점
          또는 법령에 따른 보존 기간이 경과되는 시점에 보관하던 해당 이용자의
          모든 개인정보를 삭제합니다.
          <br />
          관련법령 : 표시/광고에 관한 기록 : 6개월, 계약 또는 청약철회 등에 관한
          기록 : 5년, 대금결제 및 재화 등의 공급에 관한 기록 : 5년, 소비자의
          불만 또는 분쟁처리에 관한 기록 : 3년, 신용정보의 수집/처리 및 이용
          등에 관한 기록 : 3년
        </p>
        <p>
          <br />
          제2조(처리하는 개인정보의 항목)
        </p>
        <p>
          ① &lt; (주)아이싸이랩 &gt;은(는) 다음의 개인정보 항목을 처리하고
          있습니다.
        </p>
        <p>
          1&lt; 애니퍼피 어플리케이션 회원 정보 &gt;
          <br />
          필수항목 : 이름, 생년월일, 성별, 로그인ID, 비밀번호, 휴대전화번호,
          이메일, 쿠키, 접속 로그, 서비스 이용 기록
          <br />
          선택항목 :
        </p>
        <p>
          <br />
          제3조(개인정보의 파기절차 및 파기방법)
        </p>
        <p>
          <br />① &lt; (주)아이싸이랩 &gt; 은(는) 개인정보 보유기간의 경과,
          처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당
          개인정보를 파기합니다.
        </p>
        <p>
          ② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이
          달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야
          하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나
          보관장소를 달리하여 보존합니다.
          <br />
          1. 법령 근거 :<br />
          2. 보존하는 개인정보 항목 : 계좌정보, 거래날짜
        </p>
        <p>
          ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
          <br />
          1. 파기절차
          <br />
          &lt; (주)아이싸이랩 &gt; 은(는) 파기 사유가 발생한 개인정보를
          선정하고, &lt; (주)아이싸이랩 &gt; 의 개인정보 보호책임자의 승인을
          받아 개인정보를 파기합니다.
        </p>
        <p>2. 파기방법</p>
        <p>
          종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
        </p>
        <p>
          전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을
          사용합니다
        </p>
        <p>
          <br />
          제4조(정보주체와 법정대리인의 권리&middot;의무 및 그 행사방법에 관한
          사항)
        </p>
        <p>&nbsp;</p>
        <p>
          ① 정보주체는 (주)아이싸이랩에 대해 언제든지 개인정보
          열람&middot;정정&middot;삭제&middot;처리정지 요구 등의 권리를 행사할
          수 있습니다.
        </p>
        <p>
          ② 제1항에 따른 권리 행사는(주)아이싸이랩에 대해 「개인정보 보호법」
          시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여
          하실 수 있으며 (주)아이싸이랩은(는) 이에 대해 지체 없이
          조치하겠습니다.
        </p>
        <p>
          ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등
          대리인을 통하여 하실 수 있습니다.이 경우 &ldquo;개인정보 처리 방법에
          관한 고시(제2020-7호)&rdquo; 별지 제11호 서식에 따른 위임장을
          제출하셔야 합니다.
        </p>
        <p>
          ④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항,
          제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
        </p>
        <p>
          ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
          대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
        </p>
        <p>
          ⑥ (주)아이싸이랩은(는) 정보주체 권리에 따른 열람의 요구,
          정정&middot;삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가
          본인이거나 정당한 대리인인지를 확인합니다.
        </p>
        <p>&nbsp;</p>
        <p>제5조(개인정보의 안전성 확보조치에 관한 사항)</p>
        <p>
          &lt; (주)아이싸이랩 &gt;은(는) 개인정보의 안전성 확보를 위해 다음과
          같은 조치를 취하고 있습니다.
        </p>
        <p>
          1. 내부관리계획의 수립 및 시행
          <br />
          개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고
          있습니다.
        </p>
        <p>
          2. 개인정보 취급 직원의 최소화 및 교육
          <br />
          개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여
          개인정보를 관리하는 대책을 시행하고 있습니다.
        </p>
        <p>
          3. 정기적인 자체 감사 실시
          <br />
          개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를
          실시하고 있습니다.
        </p>
        <p>
          4. 개인정보에 대한 접근 제한
          <br />
          개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의
          부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한
          조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을
          통제하고 있습니다.
        </p>
        <p>
          5. 접속기록의 보관 및 위변조 방지
          <br />
          개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고
          있으며,다만, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나,
          고유식별정보 또는 민감정보를 처리하는 경우에는 2년이상 보관, 관리하고
          있습니다.
          <br />
          또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고
          있습니다.
        </p>
        <p>
          6. 개인정보의 암호화
          <br />
          이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어,
          본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화
          하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고
          있습니다.
        </p>
        <p>
          7. 해킹 등에 대비한 기술적 대책
          <br />
          &lt;(주)아이싸이랩&gt;('애니퍼피')은 해킹이나 컴퓨터 바이러스 등에
          의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고
          주기적인 갱신&middot;점검을 하며 외부로부터 접근이 통제된 구역에
          시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
        </p>
        <p>
          8. 비인가자에 대한 출입 통제
          <br />
          개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해
          출입통제 절차를 수립, 운영하고 있습니다.
        </p>
        <p>
          9. 문서보안을 위한 잠금장치 사용
          <br />
          개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한
          장소에 보관하고 있습니다.
        </p>
        <p>&nbsp;</p>
        <p>
          <br />
          제6조(개인정보를 자동으로 수집하는 장치의 설치&middot;운영 및 그
          거부에 관한 사항)
        </p>
        <p>&nbsp;</p>
        <p>
          ① (주)아이싸이랩 은(는) 이용자에게 개별적인 맞춤서비스를 제공하기 위해
          이용정보를 저장하고 수시로 불러오는 &lsquo;쿠키(cookie)&rsquo;를
          사용합니다.
          <br />② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의
          컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의
          하드디스크에 저장되기도 합니다.
          <br />
          가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한
          방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게
          최적화된 정보 제공을 위해 사용됩니다.
          <br />
          나. 쿠키의 설치&bull;운영 및 거부 : 웹브라우저 상단의 도구&gt;인터넷
          옵션&gt;개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수
          있습니다.
          <br />
          다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수
          있습니다.
        </p>
        <p>제7조 (개인정보 보호책임자에 관한 사항)</p>
        <p>
          ① (주)아이싸이랩 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고,
          개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여
          아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
        </p>
        <p>
          ▶ 개인정보 보호책임자
          <br />
          성명 :김재익
          <br />
          직책 :팀원
          <br />
          직급 :책임연구원
          <br />
          연락처 :070-4762-7763, privacy@iscilab.com,
          <br />※ 개인정보 보호 담당부서로 연결됩니다.
        </p>
        <p>
          ▶ 개인정보 보호 담당부서
          <br />
          부서명 :AI연구개발팀
          <br />
          담당자 :김재익
          <br />
          연락처 :070-4762-7763, privacy@iscilab.com,
          <br />② 정보주체께서는 (주)아이싸이랩 의 서비스(또는 사업)을
          이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제
          등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수
          있습니다. (주)아이싸이랩 은(는) 정보주체의 문의에 대해 지체 없이 답변
          및 처리해드릴 것입니다.
        </p>
        <p>
          제8조(개인정보의 열람청구를 접수&middot;처리하는 부서)
          <br />
          정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를
          아래의 부서에 할 수 있습니다.
          <br />
          &lt; (주)아이싸이랩 &gt;은(는) 정보주체의 개인정보 열람청구가 신속하게
          처리되도록 노력하겠습니다.
        </p>
        <p>
          ▶ 개인정보 열람청구 접수&middot;처리 부서
          <br />
          부서명 : AI연구개발팀
          <br />
          담당자 : 김재익
          <br />
          연락처 : 070-4762-7763, privacy@iscilab.com,
        </p>
        <p>
          <br />
          제9조(정보주체의 권익침해에 대한 구제방법)
        </p>
        <p>&nbsp;</p>
        <p>
          정보주체는 개인정보침해로 인한 구제를 받기 위하여
          개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에
          분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의
          신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
        </p>
        <p>
          1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
          <br />
          2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
          <br />
          3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)
          <br />
          4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
        </p>
        <p>
          「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의
          정정&middot;삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에
          대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는
          이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을
          청구할 수 있습니다.
        </p>
        <p>
          ※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr)
          홈페이지를 참고하시기 바랍니다.
        </p>
        <p>제10조(개인정보 처리방침 변경)</p>
        <p>
          <br />① 이 개인정보처리방침은 2023년 5월 19부터 적용됩니다.
        </p>
      </div>
    </>
  );
};

export default Privacy;
