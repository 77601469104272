import React from "react";

import SocialLink from "./SocialLink";
import { Container, Grid, Link, Typography } from "@mui/material";

const Footer = (props) => {
  return (
    <div className="footer">
      <Container>
        <Grid
          container
          display={"flex"}
          justifyContent={"center"}
          textAlign="center"
          pt={5}
          py={15}
        >
          <Grid item xs={12} md={12}>
            <Typography gutterBottom className="hover-under-line">
              <Link
                underline="hover"
                href="https://www.iscilab.com"
                sx={{ color: "white" }}
              >
                (주)아이싸이랩
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} mt={2}>
            <Typography>
              사업자 등록번호 : 119-81-81005 | 대표 : 최형인
            </Typography>
            <Typography>메일 : contact@anipuppy.com</Typography>
            <Typography>
              서울특별시 관악구 관악로 122(봉천동, 평창빌딩) 502호
            </Typography>
          </Grid>
          <Grid item my={5} width={"22rem"}>
            <SocialLink />
          </Grid>
          <Grid item xs={12} md={12}>
            <Link
              underline="hover"
              href="/terms"
              sx={{ color: "white", fontSize: "1rem" }}
            >
              이용약관
            </Link>
            {"  "}ㅣ{"  "}
            <Link
              underline="hover"
              href="/privacy"
              sx={{ color: "white", fontSize: "1rem" }}
            >
              개인정보처리방침
            </Link>
          </Grid>
          <Grid item xs={12} md={12} mt={3}>
            <Typography>ⓒiSciLab. All Rights Reserved.</Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Footer;
