import React from "react";
import Slider from "react-slick";

import img_icon1 from "../resources/images/tech/기술 스토리_원형01-2.png";
import img_icon2 from "../resources/images/tech/기술 스토리_원형02-2.png";
import img_icon3 from "../resources/images/tech/기술 스토리_원형03.png";
import img_icon4 from "../resources/images/tech/기술 스토리_원형04.png";
import img_icon5 from "../resources/images/tech/기술 스토리_원형05.png";
import img_icon6 from "../resources/images/tech/기술 스토리_원형06.png";
import img_icon7 from "../resources/images/tech/기술 스토리_원형07.png";
import img_icon8 from "../resources/images/tech/기술 스토리_원형08.png";
import img_icon9 from "../resources/images/tech/기술 스토리_원형09.png";
import img_icon10 from "../resources/images/tech/기술 스토리_원형10.png";
import img_icon11 from "../resources/images/tech/기술 스토리_원형11.png";

const TechStorySlider = (props) => {
  const slidesToShow = props.slidesToShow;

  let settings = {
    dots: false,
    infinite: true,
    speed: 700,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    swipeToSlide: false,
  };
  return (
    <Slider className="tech_history_carousel" {...settings}>
      <div className="tech_history_item">
        <img src={img_icon1} alt="" />
        <span className="color_gray date">2022.12</span>
        <p className="tech_history_item_title">
          <strong>
            농림축산식품부
            <br />
            우수연구개발 혁신제품 지정
          </strong>
        </p>
      </div>

      <div div className="tech_history_item">
        <img src={img_icon2} alt="" />
        <span className="color_gray date">2022.12</span>
        <p className="tech_history_item_title">
          <strong>
            NET 신기술인증
            <br />
            선정평가 통과
          </strong>
        </p>
      </div>

      <div div className="tech_history_item">
        <img src={img_icon3} alt="" />
        <span className="color_gray date">2022.09</span>
        <p className="tech_history_item_title">
          <strong>규제 샌드박스 승인</strong>
        </p>
      </div>

      <div div className="tech_history_item">
        <img src={img_icon4} alt="" />
        <span className="color_gray date">2022.08</span>
        <p className="tech_history_item_title">
          <strong>
            알고리즘 검증시험 통과 및
            <br />
            품질경영시스템 인증서 획득
          </strong>
        </p>
      </div>

      <div div className="tech_history_item">
        <img src={img_icon5} alt="" />
        <span className="color_gray date">2019.09 ~ 현재</span>
        <p className="tech_history_item_title">
          <strong>
            비문기반 바이오인식
            <br />
            동물등록 관련(PG505)
            <br />
            표준화위원 활동
          </strong>
        </p>
      </div>

      <div div className="tech_history_item">
        <img src={img_icon6} alt="" />
        <span className="color_gray date">2021.09</span>
        <p className="tech_history_item_title">
          <strong>
            동물생체인식 알고리즘별
            <br />
            수의학적 고유성 연구를 통해
            <br />
            SCI 논문 출간
          </strong>
        </p>
      </div>

      <div div className="tech_history_item">
        <img src={img_icon7} alt="" />
        <span className="color_gray date">2024.04</span>
        <p className="tech_history_item_title">
          <strong>
            애니퍼피
            <br />
            GS인증 1등급 획득
          </strong>
        </p>
      </div>

      <div div className="tech_history_item">
        <img src={img_icon8} alt="" />
        <span className="color_gray date">2024.05</span>
        <p className="tech_history_item_title">
          <strong>
            제 59회 발명의 날
            <br />
            국무총리상 표창 수상
          </strong>
        </p>
      </div>

      <div div className="tech_history_item">
        <img src={img_icon9} alt="" />
        <span className="color_gray date">2024.05</span>
        <p className="tech_history_item_title">
          <strong>
            호주 정부산하기관(GWIC)
            <br />
            납품계약 체결
          </strong>
        </p>
      </div>

      <div div className="tech_history_item">
        <img src={img_icon10} alt="" />
        <span className="color_gray date">2024.06</span>
        <p className="tech_history_item_title">
          <strong>
            2024년 상반기
            <br />
            우수발명품 선정
          </strong>
        </p>
      </div>

      <div div className="tech_history_item">
        <img src={img_icon11} alt="" />
        <span className="color_gray date">2024.09</span>
        <p className="tech_history_item_title">
          <strong>
            기술신용평가
            <br />
            TI-2 획득
          </strong>
        </p>
      </div>
    </Slider>
  );
};

export default TechStorySlider;
