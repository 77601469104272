import React from "react";

export default function PrivacyGhana() {
  return (
    <>
      <div className="terms">
        <div>
          <h1>Privacy Policy</h1>
          <p>
            &lt;(Iscilab Co., Ltd)&gt; (
            <a href="https://anipuppy.com/">https://anipuppy.com/</a>{" "}
            hereinafter referred to as 'Anipuppy') establishes and discloses
            this privacy policy to protect the personal information of data
            subjects and to promptly and smoothly handle complaints related to
            it.
          </p>

          <h2>Article 1 (Purpose of Personal Information Processing)</h2>
          <p>
            &lt;Iscilab Co., Ltd&gt; processes the personal information files
            for the following purposes:
          </p>
          <ol>
            <li>
              <strong>Name of Personal Information File:</strong> Anipuppy
              Application Member Information
            </li>
            <li>
              <strong>Purpose of Processing:</strong> To connect with
              reservation systems related to pet services in the future and to
              help find lost or stray dogs.
            </li>
            <li>
              <strong>Collection Method:</strong> The user consents to the
              collection of personal information and directly inputs information
              during membership registration and service use.
            </li>
            <li>
              <strong>Retention Grounds:</strong> User consent is obtained
              during membership registration and pet registration.
            </li>
            <li>
              <strong>Retention Period:</strong> The personal information of the
              user will be deleted at the point of one year after the user's
              last service use or membership withdrawal, or when the statutory
              retention period expires.
            </li>
            <li>
              <strong>Related Laws:</strong> Records on display/advertisement: 6
              months; Records on contracts or withdrawal of contracts: 5 years;
              Records on payment and supply of goods, etc.: 5 years; Records on
              consumer complaints or dispute resolution: 3 years; Records on the
              collection, processing, and use of credit information: 3 years.
            </li>
          </ol>

          <h2>Article 2 (Items of Personal Information Processed)</h2>
          <p>
            ① &lt;Iscilab Co., Ltd&gt; processes the following personal
            information items:
          </p>
          <ol>
            <li>Anipuppy Application Member Information</li>
            <li>
              <strong>Required Items:</strong> Name, Date of Birth, Gender,
              Login ID, Password, Mobile Phone Number, Email, Cookies, Access
              Log, Service Usage Records
            </li>
            <li>
              <strong>Optional Items:</strong> (None listed)
            </li>
          </ol>

          <h2>
            Article 3 (Procedure and Method for Destroying Personal Information)
          </h2>
          <p>
            ① &lt;Iscilab Co., Ltd&gt; shall promptly destroy personal
            information that is no longer needed due to the expiration of the
            retention period or achievement of processing purposes.
          </p>
          <p>
            ② If personal information must be retained beyond the agreed
            retention period due to other legal obligations, the information
            will be stored separately in a different database or location.
          </p>
          <ol>
            <li>
              <strong>Legal Basis:</strong>
            </li>
            <li>
              <strong>Items Retained:</strong> Account information, Transaction
              Date
            </li>
          </ol>
          <p>
            ③ The procedure and method of destroying personal information are as
            follows:
          </p>
          <ol>
            <li>
              <strong>Destruction Procedure:</strong> &lt;Iscilab Co., Ltd&gt;
              selects personal information for destruction and obtains approval
              from the personal information protection officer before
              destruction.
            </li>
            <li>
              <strong>Destruction Method:</strong> Personal information printed
              on paper is shredded or incinerated, and personal information
              stored electronically is destroyed using technical methods that
              prevent data recovery.
            </li>
          </ol>

          <h2>
            Article 4 (Rights and Obligations of Data Subjects and Legal
            Representatives and the Method of Exercising Them)
          </h2>
          <p>
            ① Data subjects may exercise their rights to request access,
            correction, deletion, and suspension of processing of their personal
            information at any time with Iscilab Co., Ltd.
          </p>
          <p>
            ② The exercise of rights pursuant to Paragraph 1 may be conducted
            via written request, email, or fax in accordance with Article 41(1)
            of the Enforcement Decree of the Personal Information Protection
            Act. Iscilab Co., Ltd will take action without delay.
          </p>
          <p>
            ③ The exercise of rights may also be carried out through legal
            representatives or delegates.
          </p>
          <p>
            ⑤ Requests for correction or deletion of personal information cannot
            be made if it is designated for collection by law.
          </p>
          <p>
            ⑥ Iscilab Co., Ltd ensures that the requester is the data subject or
            an authorized representative when processing requests for access,
            correction, deletion, or suspension of processing.
          </p>

          <h2>
            Article 5 (Measures to Ensure the Security of Personal Information)
          </h2>
          <p>
            &lt;Iscilab Co., Ltd&gt; takes the following measures to secure the
            safety of personal information:
          </p>
          <ol>
            <li>
              <strong>
                Establishment and Implementation of an Internal Management Plan:
              </strong>{" "}
              An internal management plan is established and implemented for the
              safe handling of personal information.
            </li>
            <li>
              <strong>
                Minimization and Training of Personnel Handling Personal
                Information:
              </strong>{" "}
              Only designated employees are authorized to handle personal
              information, and they receive training on privacy management.
            </li>
            <li>
              <strong>Regular Self-Audits:</strong> Regular internal audits
              (once a quarter) are conducted to ensure the security of personal
              information handling.
            </li>
            <li>
              <strong>Access Control:</strong> Access rights to personal
              information processing systems are managed through granting,
              changing, or deleting access, and unauthorized access from outside
              is blocked using firewalls.
            </li>
            <li>
              <strong>
                Retention and Prevention of Tampering of Access Logs:
              </strong>{" "}
              Access logs to personal information processing systems are kept
              for at least one year. If personal information pertains to more
              than 50,000 subjects or involves sensitive data, the logs are kept
              for at least two years.
            </li>
            <li>
              <strong>Encryption of Personal Information:</strong> Passwords and
              important data are encrypted for safe storage and transmission.
            </li>
            <li>
              <strong>Technical Countermeasures against Hacking:</strong>{" "}
              Security programs are installed, updated regularly, and monitored
              to prevent data breaches due to hacking or viruses.
            </li>
            <li>
              <strong>Physical Access Control:</strong> Areas storing personal
              information are secured with access control measures.
            </li>
            <li>
              <strong>Locking Devices for Document Security:</strong> Documents
              containing personal information are kept in secure locations with
              locking mechanisms.
            </li>
          </ol>

          <h2>
            Article 6 (Installation, Operation, and Rejection of Automatic
            Personal Information Collection Devices)
          </h2>
          <p>
            ① Iscilab Co., Ltd uses 'cookies' to provide users with personalized
            services.
          </p>
          <p>
            ② Cookies are small pieces of data sent by a website's server to the
            user's browser and stored on the user's hard drive.
          </p>
          <ol>
            <li>
              <strong>Purpose of Using Cookies:</strong> To optimize information
              provided to users by analyzing visit patterns and usage of
              services.
            </li>
            <li>
              <strong>Rejecting Cookies:</strong> Users can configure cookie
              rejection via browser settings under Tools &gt; Internet Options
              &gt; Privacy.
            </li>
            <li>
              <strong>Impact of Rejecting Cookies:</strong> Refusal of cookies
              may make it difficult to use personalized services.
            </li>
          </ol>

          <h2>Article 7 (Personal Information Protection Officer)</h2>
          <p>
            ① &lt;Iscilab Co., Ltd&gt; designates the following individual as
            the person responsible for overall management of personal
            information and handling of related complaints:
          </p>
          <ul>
            <li>
              <strong>Personal Information Protection Officer</strong>
              <ul>
                <li>Name: Jaeik Kim</li>
                <li>Position: Researcher</li>
                <li>
                  Contact: 070-4762-7763,{" "}
                  <a href="mailto:privacy@iscilab.com">privacy@iscilab.com</a>
                </li>
              </ul>
            </li>
            <li>
              <strong>Personal Information Protection Department</strong>
              <ul>
                <li>Department: AI Research and Development Team</li>
                <li>Contact Person: Jaeik Kim</li>
                <li>
                  Contact: 070-4762-7763,{" "}
                  <a href="mailto:privacy@iscilab.com">privacy@iscilab.com</a>
                </li>
              </ul>
            </li>
          </ul>

          <h2>
            Article 8 (Department Handling Requests for Access to Personal
            Information)
          </h2>
          <p>
            Requests for access to personal information under Article 35 of the
            Personal Information Protection Act can be made to the following
            department:
          </p>
          <ul>
            <li>
              <strong>Department Handling Access Requests</strong>
              <ul>
                <li>Department: AI Research and Development Team</li>
                <li>Contact Person: Jaeik Kim</li>
                <li>
                  Contact: 070-4762-7763,{" "}
                  <a href="mailto:privacy@iscilab.com">privacy@iscilab.com</a>
                </li>
              </ul>
            </li>
          </ul>

          <h2>Article 9 (Remedies for Infringement of Rights)</h2>
          <p>① This privacy policy is effective from August 16, 2024.</p>
        </div>
      </div>
    </>
  );
}
