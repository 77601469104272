import React from "react";
import "../resources/styles/modal.scss";

export const Modal = (props) => {
  const { open, close, nowMediaQuery } = props;

  return (
    <div
      className={
        "modal " +
        (open ? "openModal " : "") +
        (nowMediaQuery === "mobile" ? "mobile " : "")
      }
    >
      {open ? (
        <section>
          <main>
            {props.children}
            <button className="foot_close" onClick={close}>
              닫기
            </button>
          </main>
        </section>
      ) : null}
    </div>
  );
};
