/* eslint-disable jsx-a11y/alt-text */
import * as React from "react";

import anipuppy_blue_logo_img from "../resources/images/logo/anipuppy-blue-logo.png";

import korea from "../resources/images/korea.png";

import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/base";
import {
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

const Header = (props) => {
  const nowMediaQuery = props.nowMediaQuery;

  function refreshPage() {
    window.history.replaceState(null, null, " ", "/");
  }
  function clickedModalBtn() {
    props.clickedModalBtn();
  }

  const menus = [
    { title: "브랜드 스토리", link: "/brand-story" },
    { title: "기술 스토리", link: "/tech-story" },
    { title: "자주 묻는 질문", link: "/faq" },
    {
      title: "규제샌드박스",
      icon: <img className="korea_mark" src={korea} alt="korea logo" />,
      onclick: clickedModalBtn,
    },
  ];

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const clickHandler = (event, menu) => {
    window.location.href = menu.link;
  };

  const list = (anchor) => (
    <Box
      sx={{
        fontSize: 100,
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List sx={{ p: 0 }}>
        <ListItem
          key="menu_header"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* <Link to="/" onClick={refreshPage} className="header_logo"> */}
          <img
            className="anipuppy_logo_img"
            src={anipuppy_blue_logo_img}
            alt="애니퍼피 로고"
          />
          {/* </Link> */}
          <IconButton onClick={toggleDrawer(anchor, false)}>
            <CloseIcon sx={{ fontSize: 30 }} />
          </IconButton>
        </ListItem>
        {menus.map((menu, index) => (
          <>
            <Divider sx={{ color: "#EDEDED" }} variant="fullWidth" />
            <ListItem key={menu.text} disablePadding>
              <ListItemButton
                onClick={
                  menu.onclick ? menu.onclick : (e) => clickHandler(e, menu)
                }
                sx={{
                  py: 2,
                  display: "flex",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                {menu.icon}
                <Typography variant="h5" className="">
                  {menu.title}
                </Typography>
              </ListItemButton>
            </ListItem>
          </>
        ))}
      </List>
    </Box>
  );

  return (
    <header>
      <Grid
        container
        p={2}
        className="header"
        sx={{
          position: "absolute",
          zIndex: 1000,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid item alt="애니퍼피">
          <Link to="/" onClick={refreshPage} className="header_logo">
            <img className="anipuppy_logo_img" src={anipuppy_blue_logo_img} />
          </Link>
        </Grid>
        {nowMediaQuery === "mobile" ? (
          <React.Fragment key="top">
            <Button onClick={toggleDrawer("top", true)}>
              <MenuIcon sx={{ color: "black", fontSize: 40 }} />
            </Button>
            <Drawer
              anchor="top"
              open={state["top"]}
              onClose={toggleDrawer("top", false)}
            >
              {list("top")}
            </Drawer>
          </React.Fragment>
        ) : (
          <Grid item className="navbar_right">
            <Stack direction="row" spacing={2} className="header_menu">
              <li className="sandbox_btn" onClick={clickedModalBtn}>
                <img className="korea_mark" src={korea} />
                규제 샌드박스
              </li>
              <li>
                <Link to="/brand-story">브랜드 스토리</Link>
              </li>

              <li>
                <Link to="/tech-story">기술 스토리</Link>
              </li>

              <li>
                <Link to="/faq">자주 묻는 질문</Link>
              </li>
            </Stack>
          </Grid>
        )}
      </Grid>
    </header>
  );
};

export default Header;
